import {
  addOrdinalSuffix,
  formatLongDateWithDayFirst,
  formatNumericDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { trimEnd } from 'lodash';
import { getCoverTypeName } from 'helpers/getCoverTypeName';
import { getVetFeeLimit } from 'helpers/getVetFeeLimit';
import {
  formatInPounds,
  formatStringInPounds,
  formatStringInPoundsWithSign,
} from 'helpers/poundsHelpers';
import { Product } from 'helpers/productHelpers';
import { capitaliseFirstCharacter } from 'helpers/stringHelpers';
import { CurrentQuote } from 'helpers/useCurrentQuote';
import { ConfirmationQuote } from 'state/quote/confirmationQuote';
import { CsPlaceholders } from './replaceCsPlaceholders';

export const combinePetNames = (quote: CurrentQuote): string => {
  const petNames = quote.petInfos?.map((pet) => trimEnd(pet.petName)) ?? [''];
  if (petNames.length === 1) {
    return petNames[0];
  }
  return `${petNames.slice(0, petNames.length - 1).join(', ')} and ${
    petNames[petNames.length - 1]
  }`;
};
export const combinePetNamesWithAnd = (petNames: string[]): string => {
  if (petNames.length === 1) {
    return petNames[0];
  }
  return `${petNames.slice(0, petNames.length - 1).join(', ')} and ${
    petNames[petNames.length - 1]
  }`;
};

export const combinePetNamesWithOr = (quote: CurrentQuote): string => {
  const petNames = quote.petInfos?.map((pet) => trimEnd(pet.petName)) ?? [''];
  if (petNames.length === 1) {
    return petNames[0];
  }
  return `${petNames.slice(0, petNames.length - 1).join(', ')} or ${
    petNames[petNames.length - 1]
  }`;
};

const hardcodeMultiplePetNames = (quote: CurrentQuote): string => {
  const petNames = quote.petInfos?.map((pet) => trimEnd(pet.petName)) ?? [''];
  if (petNames.length === 1) {
    return petNames[0];
  }

  return 'your pets';
};

export const quotePlaceholders: CsPlaceholders<CurrentQuote> = {
  petNames: {
    getSubstitutionText: (quote) => combinePetNames(quote),
    isPii: false,
  },
  petNamesWithOr: {
    getSubstitutionText: (quote) => combinePetNamesWithOr(quote),
    isPii: false,
  },
  monthlyPaymentAmount: {
    getSubstitutionText: (quote) => formatInPounds(quote.price?.monthlyPrice.total),
    isPii: false,
  },
  monthlyTotalAmount: {
    getSubstitutionText: (quote) => {
      const monthlyAmount = quote.price?.monthlyPrice.total;
      return formatInPounds(monthlyAmount && monthlyAmount * 12);
    },
    isPii: false,
  },
  annualPaymentAmount: {
    getSubstitutionText: (quote) => formatInPounds(quote.price?.annualPrice.total),
    isPii: false,
  },
  customerEmail: {
    getSubstitutionText: (quote) => quote.customerInfo?.email ?? '',
    isPii: true,
  },
  customerFirstName: {
    getSubstitutionText: (quote) => quote.customerInfo?.firstName ?? '',
    isPii: true,
  },
  quoteReference: {
    getSubstitutionText: (quote) => quote.policyInfo?.quoteNumber ?? '',
    isPii: false,
  },
  quoteExpiryDate: {
    getSubstitutionText: (quote) =>
      quote.policyInfo?.quoteExpiryDate
        ? formatNumericDate(new Date(quote.policyInfo?.quoteExpiryDate))
        : '',
    isPii: false,
  },
  coverStartDate: {
    getSubstitutionText: (quote) =>
      quote.policyInfo?.coverStartDate
        ? formatNumericDate(new Date(quote.policyInfo?.coverStartDate))
        : '',
    isPii: false,
  },
  longCoverStartDate: {
    getSubstitutionText: (quote) =>
      quote.policyInfo?.coverStartDate
        ? formatLongDateWithDayFirst(new Date(quote.policyInfo?.coverStartDate))
        : '',
    isPii: false,
  },
  selectedCoverLevel: {
    getSubstitutionText: (quote) =>
      capitaliseFirstCharacter(getCoverTypeName(quote.quoteOptions)),
    isPii: false,
  },
  coverLimit: {
    getSubstitutionText: (quote) => formatStringInPounds(getVetFeeLimit(quote)),
    isPii: false,
  },
  vetFeesLimit: {
    getSubstitutionText: (quote) => {
      if (quote?.petInfos?.length) {
        const vetFeesLimit = quote?.petInfos?.map((pet) => pet.voluntaryExcessAmount);
        return formatStringInPoundsWithSign(vetFeesLimit[0]);
      }
      return '';
    },
    isPii: false,
  },
};

export const getQuotePlaceholdersWithProductOption = (
  product: Product
): CsPlaceholders<CurrentQuote> => ({
  ...quotePlaceholders,
  coverLevel: {
    getSubstitutionText: () => capitaliseFirstCharacter(product),
    isPii: false,
  },
  coverMonthlyPaymentAmount: {
    getSubstitutionText: (quote) => formatInPounds(quote.price?.monthlyPrice.total),
    isPii: false,
  },
  coverAnnualPaymentAmount: {
    getSubstitutionText: (quote) => formatInPounds(quote.price?.annualPrice.total),
    isPii: false,
  },
});

export const confirmationQuotePlaceholders: CsPlaceholders<ConfirmationQuote> = {
  ...quotePlaceholders,
  monthlyPaymentDate: {
    getSubstitutionText: (confirmationQuote) =>
      addOrdinalSuffix(confirmationQuote.monthlyPaymentDate ?? 0),
    isPii: false,
  },
  hardcodedMultiplePetNames: {
    getSubstitutionText: (quote) => hardcodeMultiplePetNames(quote),
    isPii: false,
  },
};
