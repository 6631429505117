import { CoverLimit } from './businessConstants';
import { getProductFromQuoteOptions, Product } from './productHelpers';
import { CurrentQuote } from './useCurrentQuote';

export const getVetFeeLimit = (quote: CurrentQuote): string | undefined => {
  const product = getProductFromQuoteOptions(quote.quoteOptions);

  switch (product) {
    case Product.Essential:
      return CoverLimit.Essential.toString();
    case Product.Standard:
      return CoverLimit.Standard.toString();
    case Product.Plus:
      return quote.quoteOptions.plusCoverFeeLimit;
    case Product.Premier:
      return quote.quoteOptions.premierCoverFeeLimit;
    default:
      return undefined;
  }
};

export const formatVetFeesLimit = (vetFeesLimit: string): string => {
  return `£${vetFeesLimit.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')}`;
};
