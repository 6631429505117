import { dateValueToISODateString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { getInferedGenderFromTitle, hasGenderlessTitle } from 'helpers/genderHelpers';
import { conditionalValue } from 'helpers/mappingHelpers';
import { maritalStatus_NOT_NEEDED } from 'helpers/referenceDataConstants';
import { CustomerDetails } from 'state/formData/customerDetails';
import mapAddress from './mapAddress';
import mapAddressLookup from './mapAddressLookup';
import { QuoteCustomerInfo } from '../quoteRequest';

const mapCustomerInfo = (customerDetails: CustomerDetails): QuoteCustomerInfo => ({
  title: customerDetails.customerTitle,
  firstName: customerDetails.customerFirstName,
  lastName: customerDetails.customerLastName,
  gender:
    getInferedGenderFromTitle(customerDetails.customerTitle) ||
    conditionalValue(
      hasGenderlessTitle(customerDetails),
      customerDetails.customerGender
    ) ||
    '',
  dob: dateValueToISODateString(customerDetails.customerDob) || '',
  email: customerDetails.customerEmail,
  contactPhoneNumber: customerDetails.customerTelephone,
  address: customerDetails.isManualAddress
    ? mapAddress(customerDetails)
    : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      mapAddressLookup(customerDetails.address!),
  maritalStatus: maritalStatus_NOT_NEEDED,
});

export default mapCustomerInfo;
