import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import { initialDateValue } from './shared/dateValue';

export const UPDATE_JOINT_POLICYHOLDER_DETAILS = 'UPDATE_JOINT_POLICYHOLDER_DETAILS';

export type JointPolicyholderDetails = {
  includeJointPolicyholder: boolean;
  jointPolicyholderFirstName: string;
  jointPolicyholderLastName: string;
  jointPolicyholderDob: DateValue;
  jointPolicyholderTitle: string;
};

export const initialJointPolicyholderDetails: JointPolicyholderDetails = {
  includeJointPolicyholder: false,
  jointPolicyholderFirstName: '',
  jointPolicyholderLastName: '',
  jointPolicyholderDob: initialDateValue,
  jointPolicyholderTitle: '',
};

export type UpdateJointPolicyholderDetailsAction = {
  type: typeof UPDATE_JOINT_POLICYHOLDER_DETAILS;
  update: JointPolicyholderDetails;
};

export const useJointPolicyholderDetails = (): [
  JointPolicyholderDetails,
  (update: Partial<JointPolicyholderDetails>) => void
] => {
  const jointPolicyholderDetails = useSelector(
    (state: RootState) => state.jointPolicyholderDetails
  );
  const dispatch = useDispatch();

  const updateJointPolicyholderDetails = useCallback(
    (update: Partial<JointPolicyholderDetails>): void => {
      dispatch({ type: UPDATE_JOINT_POLICYHOLDER_DETAILS, update });
    },
    [dispatch]
  );

  return [jointPolicyholderDetails, updateJointPolicyholderDetails];
};
