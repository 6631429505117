import { AxiosError } from 'axios';

const RECAPTCHA_BELOW_THRESHOLD = 'EVO_BUS_P354';

type AxiosErrorWithReCaptchaResponse = AxiosError & {
  response: {
    data: {
      items: [
        {
          recaptchaStatus: boolean;
          recaptchaScore: number;
        }
      ];
    };
  };
};

export const isReCaptchaThresholdError = (
  error: AxiosError
): error is AxiosErrorWithReCaptchaResponse =>
  error.response?.status === 412 &&
  error.response?.data?.Code === RECAPTCHA_BELOW_THRESHOLD;
