import { call, CallEffect, takeEvery } from 'redux-saga/effects';
import {
  DISABLE_DATE_CHECKS_STORAGE_KEY,
  retrieveData,
  storeData,
} from 'helpers/sessionStorageHelpers';
import { UPDATE_DISABLE_DATE_CHECKS, UpdateDisableDateChecksAction } from './actions';

export const getInitialDisableDateChecks = (): boolean =>
  retrieveData(DISABLE_DATE_CHECKS_STORAGE_KEY) === 'true';

function* storeDisableDateChecks(
  action: UpdateDisableDateChecksAction
): Generator<CallEffect<void>> {
  yield call(() =>
    storeData(DISABLE_DATE_CHECKS_STORAGE_KEY, action.disableDateChecks.toString())
  );
}

function* handleUpdateDisableDateChecks(): Generator<unknown> {
  yield takeEvery(UPDATE_DISABLE_DATE_CHECKS, storeDisableDateChecks);
}

export default handleUpdateDisableDateChecks;
