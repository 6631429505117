import { v4 as uuidv4 } from 'uuid';
import {
  retrieveData,
  storeData,
  TRANSACTION_ID_SESSION_KEY,
} from './sessionStorageHelpers';

export const getTransactionId = (): string => {
  const transactionId = retrieveData(TRANSACTION_ID_SESSION_KEY);

  if (transactionId !== undefined) {
    return transactionId;
  }

  const newTransactionId = uuidv4();
  storeData(TRANSACTION_ID_SESSION_KEY, newTransactionId);
  return newTransactionId;
};
