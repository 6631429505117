import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ErrorPage, { ErrorPageData } from 'templates/ErrorPage';

export const query = graphql`
  query {
    csErrorPage(error_id: { eq: "pet-quote-converted" }) {
      ...ErrorPage
    }
  }
`;

/* istanbul ignore next */
const ConvertedQuoteErrorPage: React.FC = () => {
  const data = useStaticQuery<ErrorPageData>(query);
  return <ErrorPage data={data} />;
};

export default ConvertedQuoteErrorPage;
