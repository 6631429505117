import { CoverLevel } from 'helpers/businessConstants';
import { CustomerDetails } from 'state/formData/customerDetails';
import { JointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { PetsDetails } from 'state/formData/petsDetails';
import { PolicyDetails } from 'state/formData/policyDetails';
import {
  generateRequoteParameters,
  getCoverLevelFromQuoteOptions,
  getQuoteOptionsFromQuote,
} from './bundleCoverMapping';
import mapCustomerInfo from './formToQuoteMappings/mapCustomerInfo';
import mapPetsInfo from './formToQuoteMappings/mapPetsInfo';
import mapPolicyInfo from './formToQuoteMappings/mapPolicyInfo';
import { QuoteRequest, RequoteRequest } from './quoteRequest';
import { Quote } from './quoteResponse';

const mapFormToQuote = (
  petsDetails: PetsDetails,
  customerDetails: CustomerDetails,
  jointPolicyholderDetails: JointPolicyholderDetails,
  policyDetails: PolicyDetails,
  disableDateChecks: boolean,
  coverLevelRequired: CoverLevel,
  storedQuote?: Quote
): QuoteRequest => ({
  petInfos: mapPetsInfo(petsDetails, new Date(policyDetails.coverStartDate), storedQuote),
  customerInfo: mapCustomerInfo(customerDetails),
  policyInfo: {
    ...mapPolicyInfo(
      customerDetails,
      policyDetails,
      jointPolicyholderDetails,
      storedQuote?.policyInfo
    ),
    disableDateChecks,
  },
  coverLevelRequired,
});

export const mapFormToRequote = (
  petsDetails: PetsDetails,
  customerDetails: CustomerDetails,
  jointPolicyholderDetails: JointPolicyholderDetails,
  policyDetails: PolicyDetails,
  disableDateChecks: boolean,
  storedQuote: Quote
): RequoteRequest => {
  const quoteOptions = getQuoteOptionsFromQuote(storedQuote);
  const selectedCoverLevel = getCoverLevelFromQuoteOptions(quoteOptions);
  const requestCoverLevel = selectedCoverLevel || CoverLevel.ESSENTIAL;

  return {
    ...mapFormToQuote(
      petsDetails,
      customerDetails,
      jointPolicyholderDetails,
      policyDetails,
      disableDateChecks,
      requestCoverLevel,
      storedQuote
    ),
    ...generateRequoteParameters(
      storedQuote,
      quoteOptions,
      requestCoverLevel,
      policyDetails.coverStartDate
    ),
  };
};

export default mapFormToQuote;
