import { FullAddress } from 'apiHelpers/address/address';
import { nations_UK } from 'helpers/referenceDataConstants';
import { QuoteAddress } from '../quoteRequest';

const mapAddressLookup = (address: FullAddress): QuoteAddress => ({
  town: address.town,
  county: address.county,
  flatNumber: address.flatNumber,
  flatName: address.flatName,
  houseNumber: address.houseNumber,
  houseName: address.houseName,
  street: address.street,
  postcode: address.postcode,
  country: nations_UK,
});

export default mapAddressLookup;
