import SecondaryAccordion from '@rsa-digital/evo-shared-components/components/Accordion/SecondaryAccordion';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { TableCell } from '@rsa-digital/evo-shared-components/components/Table';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import {
  heavyFontWeight,
  semiBoldFontWeight,
} from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled, { css } from 'styled-components';

export const ModalHeading = styled.h2`
  ${fonts.headingSmall};

  & {
    font-weight: ${semiBoldFontWeight};
  }

  margin-bottom: ${spacing(4)};
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(2)};
  `}

  ${mediaQuery.desktop`
    margin-bottom: ${spacing(4)};
  `}
`;

export const TableWrapper = styled.div`
  margin-bottom: ${spacing(3)};
  flex-direction: column;

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}
`;

export const TableTitle = styled.div`
  ${fonts.paragraphLarge}
  && {
    font-weight: ${heavyFontWeight};
    color: ${colors.core02};
  }
  padding: ${spacing(1)} 0;
  ${mediaQuery.tabletLandscape`
    padding: 0;
  `}
  background-color: ${colors.core03};
`;
export const TableSubTitle = styled.p`
  && {
    ${fonts.paragraphSmall}
    white-space: normal;
  }

  &&&& {
    margin: ${spacing(0.5)} auto;
    color: ${colors.core02};
  }
  ${mediaQuery.tabletPortrait`
    && {
      white-space: nowrap;
      ${fonts.paragraph}    
    }
    `}
`;

const cellStyling = css`
  ${fonts.paragraphSmall}
  padding: ${spacing(2)} ${spacing(1)};
  
  ${mediaQuery.tabletPortrait`
    padding: ${spacing(1.5)} ${spacing(2)};
  `}

  background-color: ${colors.notificationSuccessLight};
`;

export const PaddedTableCell = styled(TableCell)`
  ${cellStyling}
  ${fonts.paragraph};
  height: ${spacing(7)};
  width: 33.33%;
`;

export const TableHeadingCell = styled(TableCell)`
  ${cellStyling}
   height: ${spacing(10)};
  & {
    background-color: ${colors.core03};
  }
`;

export const ColumnHeadingCell = styled(TableCell)`
  ${cellStyling}
  height: ${spacing(8)};
  && {
    padding: ${spacing(1)};
  }
  ${fonts.paragraph};
  & {
    font-weight: ${semiBoldFontWeight};
  }
  text-align: center;
`;

export const RowHeadingCell = styled(TableCell)`
  ${cellStyling}
  ${fonts.paragraph};
  & {
    font-weight: ${semiBoldFontWeight};
    min-width: ${spacing(12)};
  }

  div {
    word-break: break-word;
  }

  ${mediaQuery.tabletPortrait`
    div {
      word-break: normal;
    }
  `}
`;

export const MobileAccordion = styled(SecondaryAccordion)`
  margin-bottom: ${spacing(5)};
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(5)};
    margin-bottom: ${spacing(4)};
  `}

  ${mediaQuery.desktop`
    margin-top: 0;
    display: none;
  `}

   table {
    table-layout: auto;
    margin-top: ${spacing(2)};
    margin-bottom: ${spacing(1)};
  }

  table :last-child {
    margin-bottom: auto;
  }
`;

export const DesktopAccordion = styled(SecondaryAccordion)`
  display: none;

  ${mediaQuery.desktop`
    display: flex;
  `}

  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(3)};

  ${TableWrapper} {
    display: flex;
  }

  table {
    table-layout: auto;
    margin-top: ${spacing(3)};
    margin-bottom: ${spacing(1)};  
    max-width: ${spacing(68)};
  }

  table :last-child {
    margin-bottom: auto;  
  }

  tbody td {
    div {
      min-height: 2.5rem;
      align-items: center;
    }
  }
`;

export const GridWithBottomMargin = styled(Grid)`
  & {
    ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(3)};
    `}

    ${mediaQuery.desktop`
      margin-bottom: ${spacing(4)};
    `}
  }
`;
