import { RESET_STATE, ResetStateAction } from 'state/actions';
import {
  initialQuoteOptions,
  QuoteOptions,
  UPDATE_QUOTE_OPTIONS,
  UpdateQuoteOptionsAction,
} from './quoteOptions';

export const quoteOptionsReducer = (
  quoteOptions: QuoteOptions = initialQuoteOptions,
  action: UpdateQuoteOptionsAction | ResetStateAction
): QuoteOptions => {
  switch (action.type) {
    case UPDATE_QUOTE_OPTIONS:
      return { ...quoteOptions, ...action.update };
    case RESET_STATE:
      return initialQuoteOptions;
    default:
      return quoteOptions;
  }
};
