import { RESET_STATE, ResetStateAction } from 'state/actions';
import { ERROR, ErrorAction, resetErrorStateAction } from './actions';
import { ErrorState } from './state';

const errorReducer = (
  state: ErrorState = {},
  action: ErrorAction | ResetStateAction
): ErrorState => {
  switch (action.type) {
    case ERROR:
      return { errorType: action.errorType, statusCode: action.statusCode };
    case RESET_STATE:
      return {
        errorType: resetErrorStateAction.errorType,
        statusCode: resetErrorStateAction.statusCode,
      };
    default:
      return state;
  }
};

export default errorReducer;
