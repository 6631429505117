import { call, CallEffect, takeEvery } from 'redux-saga/effects';
import {
  ASSUMPTIONS_AGREEMENT_SESSION_KEY,
  retrieveData,
  storeData,
} from 'helpers/sessionStorageHelpers';
import {
  AssumptionsAgreement,
  UPDATE_ASSUMPTIONS_AGREEMENT,
  UpdateAssumptionsAgreementAction,
} from './assumptionsAgreement';

function* storeAssumptionsAgreement(
  action: UpdateAssumptionsAgreementAction
): Generator<CallEffect<void>> {
  yield call(() => {
    const previousAssumptionsAgreement: AssumptionsAgreement = JSON.parse(
      retrieveData(ASSUMPTIONS_AGREEMENT_SESSION_KEY) ?? '{}'
    );
    storeData(
      ASSUMPTIONS_AGREEMENT_SESSION_KEY,
      JSON.stringify({ ...previousAssumptionsAgreement, ...action.update })
    );
  });
}

function* handleStoreAssumptionsAgreement(): Generator<unknown> {
  yield takeEvery(UPDATE_ASSUMPTIONS_AGREEMENT, storeAssumptionsAgreement);
}
export default handleStoreAssumptionsAgreement;
