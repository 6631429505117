import RichText, {
  RichTextProps,
} from '@rsa-digital/evo-shared-components/components/RichText';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import CustomModal from 'components/CustomModal';
import { PageTitle, trackRichTextLinkClick } from 'helpers/eventTracking';
import useRichTextWithModal from './useRichTextwithModal';

/**
 * This should be used whenever the content editors can include a modal link in the rich text. This
 * is done by using the HTML editing mode of the rich text editor, and creating a button element:
 *
 * <button data-modal="modal-id">link text here</button>
 *
 * where modal-id is defined in contentstack.
 *
 * This component handles the control and rendering of the modal(s) in this rich text block.
 */
const RichTextWithModal: React.FC<
  RichTextProps & ComponentProps & { pageTitle: PageTitle }
> = ({ pageTitle, html, customBulletIcon, onLinkClick, ...props }) => {
  const { modalId, setModalId, handleModalButtonClick } = useRichTextWithModal();

  return (
    // We are only catching *bubbled* events from child *button* elements which are interactive, so these rules
    // do not apply here.
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div onClick={handleModalButtonClick} {...componentProps(props)}>
      <CustomModal
        modalId={modalId}
        onClose={() => {
          setModalId(null);
        }}
        pageTitle={pageTitle}
        trackRichTextLinkClick={trackRichTextLinkClick(pageTitle)}
      />
      <RichText
        html={html}
        customBulletIcon={customBulletIcon}
        onLinkClick={onLinkClick || trackRichTextLinkClick(pageTitle)}
      />
    </div>
  );
};

export default RichTextWithModal;
