/**
 * Use for warnings that should include additional detail on build or
 * during development, but not client-side in production.
 *
 * @param message explaining the error
 * @param detail that should not be shown to users
 */
/* istanbul ignore next */
export const warningWithDetail = (message: string, detail: string): void => {
  if (typeof window !== 'undefined' && process.env.NODE_ENV === 'production') {
    console.warn(message);
  } else {
    console.warn(`${message} ${detail}`);
  }
};

/**
 * Use for warnings to emit during the build and development,
 * but hidden from clients in production.
 *
 * @param message explaining the warning
 */
/* istanbul ignore next */
export const buildWarning = (message: string): void => {
  if (typeof window === 'undefined' || process.env.NODE_ENV !== 'production') {
    console.warn(message);
  }
};

/**
 * Use for non-fatal errors that we want to fail the build but only be a warning
 * during development or client-side in production.
 *
 * @param message explaining the error
 * @param detail that should not be shown to users
 */
/* istanbul ignore next */
export const nonFatalBuildError = (message: string, detail: string): void => {
  if (typeof window === 'undefined') {
    // Running as part of the build process: throw an error with full detail
    throw new Error(`${message} ${detail}`);
  } else {
    warningWithDetail(message, detail);
  }
};
