export const quoteAndBuyRoutes = {
  aboutYouAndYourPet: '/pet/about-you-and-your-pet/',
  additionalQuestions: '/pet/additional-questions/',
  loadingQuote: '/pet/quote-generating/',
  quoteSummary: '/pet/quote-summary/',
  checkYourDetails: '/pet/check-your-details/',
  payment: '/pet/payment/',
  paymentRedirect: '/pet/payment/redirect/',
  paymentDone: '/pet/payment/done/',
  paymentBack: '/pet/payment/back/',
  paymentError: '/pet/payment/error/',
  confirmation: '/pet/confirmation/',
  retrieveQuote: '/pet/retrieve-quote/',
  retrieveQuoteReference: '/pet/retrieve-quote-reference/',
  ineligibleQuote: '/pet/ineligible-quote/',
  sessionExpired: '/pet/session-expired/',
  confirmationSessionExpired: '/pet/confirmation-session-expired/',
  confirmCoverStartDate: '/pet/confirm-cover-start-date/',
};

// The prod environment uses the Blue Green Deployment approach, but only specifies one GATSBY_SITE_URL, which
// is the live one. This means that some functionality, like the annual payment iframe, doesn't work on
// the blue or green URLs due to different origin issues. We fix this by using window.location.origin if it
// matches one of our predefined allowed origins, otherwise we still use GATSBY_SITE_URL
export const getSiteUrl = (): string | undefined =>
  (typeof window !== 'undefined' &&
    process.env.GATSBY_VALID_URLS &&
    process.env.GATSBY_VALID_URLS.split(',').find(
      (validUrl) => validUrl === window.location.origin
    )) ||
  process.env.GATSBY_SITE_URL;

// This method relies on GATSBY_SITE_URL missing a trailing slash and the URLs above including a leading slash
// We can't use the URL constructor because the site url has to be templated after the build process.
export const getFullQuoteAndBuyRoute = (route: keyof typeof quoteAndBuyRoutes): string =>
  `${getSiteUrl()}${quoteAndBuyRoutes[route]}`;
