import { LookupStatus } from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { QuoteAddress } from 'apiHelpers/quote/quoteRequest';
import { AddressDetails } from 'state/formData/customerDetails';

/**
 * AIL/the API returns quotes with addresses in the same form regardless of whether it was originally
 * populated from a lookup or manually.
 * - We can't assume manual entry because lots of lookups don't adhere to the same validation rules,
 * so the user would have validation errors in their form.
 * - We can't assume lookup because not all addresses are available via lookup.
 *
 * Our solution is to show the address as a lookup (in a card) BUT instead of passing any of the address
 * back to the API, instead tell it to use the previous value of the address unless they explicitly make
 * a change. To do this we:
 * 1) set the usePreviousAddress property to true (so that we can set it on a quote re-rate request, and
 * to facilitate the appropriate validation)
 * 2) set isManualAddress to false and construct a dummy address object for the address input (lookup) to
 * use (so that it masquerades as a lookup)
 *
 * We also populate the manual fields in case the user wants to switch to manual entry - their info is
 * pre-populated.
 */
const mapAddress = (quoteAddress: QuoteAddress): AddressDetails => ({
  isManualAddress: false,
  usePreviousAddress: true,
  address: {
    country: quoteAddress.country ?? '',
    county: quoteAddress.county ?? '',
    flatName: quoteAddress.flatName ?? '',
    flatNumber: quoteAddress.flatNumber ?? '',
    houseName: quoteAddress.houseName ?? '',
    houseNumber: quoteAddress.houseNumber ?? '',
    postcode: quoteAddress.postcode ?? '',
    street: quoteAddress.street ?? '',
    town: quoteAddress.town ?? '',
    village: quoteAddress.village ?? '',
    region: quoteAddress.region ?? '',
    subregion: quoteAddress.subregion ?? '',
    district: quoteAddress.district ?? '',
  },
  postcodeLookup: {
    lookupKey: quoteAddress.postcode || '',
    data: null,
    status: LookupStatus.Pending,
  },
  town: quoteAddress.town || quoteAddress.subregion || '',
  village: quoteAddress.village || '',
  county: quoteAddress.county || quoteAddress.district || quoteAddress.region || '',
  flatNameOrNumber: quoteAddress.flatName || quoteAddress.flatNumber || '',
  houseNameOrNumber: quoteAddress.houseName || quoteAddress.houseNumber || '',
  street: quoteAddress.street || '',
  postcode: quoteAddress.postcode || '',
});

export default mapAddress;
