import { localDateToUtcDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  initialLookupValue,
  LookupValue,
} from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';

export const UPDATE_POLICY_DETAILS = 'UPDATE_POLICY_DETAILS';

export type PromoCodeLookup = LookupValue<string, void>;

export type PolicyDetails = {
  coverStartDate: string;
  keepInformed: boolean | undefined;
  promoCode: PromoCodeLookup;
  referrerId: string | undefined;
};

export const initialPromoCodeLookup: PromoCodeLookup = initialLookupValue('');

export const initialPolicyDetails: PolicyDetails = {
  coverStartDate: localDateToUtcDate(new Date()).toISOString(),
  keepInformed: undefined,
  promoCode: initialPromoCodeLookup,
  referrerId: '',
};

export type UpdatePolicyDetailsAction = {
  type: typeof UPDATE_POLICY_DETAILS;
  update: PolicyDetails;
};

export const usePolicyDetails = (): [
  PolicyDetails,
  (update: Partial<PolicyDetails>) => void
] => {
  const policyDetails = useSelector((state: RootState) => state.policyDetails);
  const dispatch = useDispatch();

  const updatePolicyDetails = useCallback(
    (update: Partial<PolicyDetails>): void => {
      dispatch({ type: UPDATE_POLICY_DETAILS, update });
    },
    [dispatch]
  );

  return [policyDetails, updatePolicyDetails];
};
