import {
  addDaysToDate,
  addYearsToDate,
  dateValueToUtcDate,
  localDateToUtcDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  applyRuleIf,
  dateValueGreaterThanOrEqualTo,
  dateValueLessThanOrEqualTo,
  dateValueValid,
  dateValueValidDay,
  dateValueValidMonth,
  dateValueValidYear,
  required,
  requiredDateValue,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import {
  ValidationErrorAndWarningRules,
  ValidationRule,
} from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { assumptionsIncludeId } from 'businessLogic/aggregatorAssumptions';
import { graphql, useStaticQuery } from 'gatsby';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import { isAfterMinValidDate } from 'helpers/ageHelpers';
import {
  catBreedType_NON_PEDIGREE,
  catBreedType_PEDIGREE,
  dogBreedType_CROSS_BREED,
  dogBreedType_MONGREL,
  dogBreedType_PEDIGREE,
  petType_CAT,
  petType_DOG,
} from 'helpers/referenceDataConstants';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useDisableDateChecks from 'helpers/useDisableDateChecks';
import useKickoutMessageText from 'helpers/useKickoutMessageText';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { Pet, PetsDetails } from 'state/formData/petsDetails';
import { CsErrorsMissingOnly } from 'types/contentStack';

type CsAboutYourPetErrorMessages = {
  csPetAboutYourPetMainQuestions: {
    pet_name: CsErrorsMissingOnly;
    pet_type: CsErrorsMissingOnly;
    pet_gender: CsErrorsMissingOnly;
    pet_cost: CsErrorsMissingOnly;
    pet_spayed: CsErrorsMissingOnly;
    pet_chipped: CsErrorsMissingOnly;
    pet_lives_with_you: CsErrorsMissingOnly;
    pet_in_good_health: CsErrorsMissingOnly;
    pet_is_eligible: CsErrorsMissingOnly;
    pet_date_of_birth: {
      error_messages: {
        missing: string;
        date_in_future: string;
        too_young: string;
        too_old: string;
        invalid_day: string;
        invalid_month: string;
        invalid_date: string;
        year_too_short: string;
      };
      warning_messages: {
        young_pet: string;
        old_pet: string;
      };
    };
  };
  csPetAboutYourPetCatQuestions: {
    cat_eligibility_question: {
      error_messages: {
        missing: string;
        cat_kickout_message: string;
      };
    };
    cat_breed_type: CsErrorsMissingOnly;
    pedigree_cat_breed_name: CsErrorsMissingOnly;
    non_pedigree_cat_breed_name: CsErrorsMissingOnly;
  };
  csPetAboutYourPetDogQuestions: {
    dog_eligibility_question: {
      error_messages: {
        missing: string;
        dog_kickout_message: string;
        cat_kickout_message: string;
      };
    };
    dog_breed_type: CsErrorsMissingOnly;
    pedigree_dog_breed_name: CsErrorsMissingOnly;
    cross_breed_dog_breed_name: CsErrorsMissingOnly;
    mongrel_size: CsErrorsMissingOnly;
    dog_legal_action: CsErrorsMissingOnly;
    dog_complaints: CsErrorsMissingOnly;
  };
  csPetAggregators: {
    assumptions: {
      eligibility_question: {
        warning: string;
      };
      good_health: {
        warning: string;
      };
      no_complaints_about_behaviour: {
        warning: string;
      };
      not_involved_in_legal_action: {
        warning: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYourPetMainQuestions {
      pet_name {
        error_messages {
          missing
        }
      }
      pet_type {
        error_messages {
          missing
        }
      }
      pet_gender {
        error_messages {
          missing
        }
      }
      pet_cost {
        error_messages {
          missing
        }
      }
      pet_spayed {
        error_messages {
          missing
        }
      }
      pet_chipped {
        error_messages {
          missing
        }
      }
      pet_lives_with_you {
        error_messages {
          missing
        }
      }
      pet_in_good_health {
        error_messages {
          missing
          answered_no
        }
      }
      pet_date_of_birth {
        error_messages {
          missing
          date_in_future
          too_young
          too_old
          invalid_day
          invalid_month
          invalid_date
          year_too_short
        }
        warning_messages {
          young_pet
          old_pet
        }
      }
    }
    csPetAboutYourPetCatQuestions {
      cat_eligibility_question {
        error_messages {
          missing
          cat_kickout_message
        }
      }
      cat_breed_type {
        error_messages {
          missing
        }
      }
      pedigree_cat_breed_name {
        error_messages {
          missing
        }
      }
      non_pedigree_cat_breed_name {
        error_messages {
          missing
        }
      }
    }
    csPetAboutYourPetDogQuestions {
      dog_eligibility_question {
        error_messages {
          missing
          dog_kickout_message
          cat_kickout_message
        }
      }
      dog_breed_type {
        error_messages {
          missing
        }
      }
      pedigree_dog_breed_name {
        error_messages {
          missing
        }
      }
      cross_breed_dog_breed_name {
        error_messages {
          missing
        }
      }
      mongrel_size {
        error_messages {
          missing
        }
      }
      dog_legal_action {
        error_messages {
          missing
        }
      }
      dog_complaints {
        error_messages {
          missing
        }
      }
    }
    csPetAggregators {
      assumptions {
        eligibility_question {
          warning
        }
        good_health {
          warning
        }
        no_complaints_about_behaviour {
          warning
        }
        not_involved_in_legal_action {
          warning
        }
      }
    }
  }
`;

export const usePetRules = (): ValidationErrorAndWarningRules<Pet> => {
  const errorMessages = useStaticQuery<CsAboutYourPetErrorMessages>(query);
  const kickoutMessage = useKickoutMessageText();
  const petIsDog = (data: Pet): boolean => data.petType === petType_DOG;
  const dogIsMongrel = (data: Pet): boolean => data.dogBreedType === dogBreedType_MONGREL;
  const petIsCat = (data: Pet): boolean => data.petType === petType_CAT;
  const catIsEligible = (data: Pet): boolean => petIsCat(data) && !!data.petInGoodHealth;
  const dogIsEligible = (data: Pet): boolean =>
    petIsDog(data) && !!data.petIsEligible && !!data.petInGoodHealth;
  const petIsEligible = (data: Pet): boolean => !!data.petIsEligible;

  const disableDateChecks = useDisableDateChecks();

  const assumptions = useAssumptions();
  const [assumptionsAgreement] = useAssumptionsAgreement();
  const { petInfos: currentQuotePetInfos } = useCurrentQuote();

  const petNamesInCurrentQuote = currentQuotePetInfos?.map((pet) => {
    return pet.petName;
  });

  const petExistsOnCurrentQuote = (data: Pet): boolean => {
    if (petNamesInCurrentQuote && petNamesInCurrentQuote.indexOf(data.petName) !== -1) {
      return true;
    }
    return false;
  };

  return {
    errors: {
      petName: [
        required(
          errorMessages.csPetAboutYourPetMainQuestions.pet_name.error_messages.missing
        ),
      ],
      petType: [
        required(
          errorMessages.csPetAboutYourPetMainQuestions.pet_type.error_messages.missing
        ),
      ],
      dogBreedType: validateIf((data) => dogIsEligible(data), [
        required(
          errorMessages.csPetAboutYourPetDogQuestions.dog_breed_type.error_messages
            .missing
        ),
      ]),
      dogPedigreeBreedName: validateIf((data) => dogIsEligible(data), [
        applyRuleIf(
          (data: Pet) => data.dogBreedType === dogBreedType_PEDIGREE,
          required(
            errorMessages.csPetAboutYourPetDogQuestions.pedigree_dog_breed_name
              .error_messages.missing
          )
        ),
      ]),
      dogCrossBreedName: validateIf((data) => dogIsEligible(data), [
        applyRuleIf(
          (data: Pet) => data.dogBreedType === dogBreedType_CROSS_BREED,
          required(
            errorMessages.csPetAboutYourPetDogQuestions.cross_breed_dog_breed_name
              .error_messages.missing
          )
        ),
      ]),
      mongrelSize: validateIf((data) => dogIsMongrel(data) && dogIsEligible(data), [
        required(
          errorMessages.csPetAboutYourPetDogQuestions.mongrel_size.error_messages.missing
        ),
      ]),
      petIsEligible: validateIf(
        (data) => data.petName !== '' && (petIsDog(data) || petIsCat(data)),
        [
          required(
            errorMessages.csPetAboutYourPetDogQuestions.dog_eligibility_question
              .error_messages.missing
          ),
          applyRuleIf(petIsDog, {
            validityCondition: (value) => !!value,
            errorMessage:
              errorMessages.csPetAboutYourPetDogQuestions.dog_eligibility_question
                .error_messages.dog_kickout_message,
            onlyValidateAfterSubmission: false,
          }),
          applyRuleIf(petIsCat, {
            validityCondition: (value) => !!value,
            errorMessage:
              errorMessages.csPetAboutYourPetCatQuestions.cat_eligibility_question
                .error_messages.cat_kickout_message,
            onlyValidateAfterSubmission: false,
          }),
        ]
      ),
      catBreedType: validateIf((data) => catIsEligible(data), [
        required(
          errorMessages.csPetAboutYourPetCatQuestions.cat_breed_type.error_messages
            .missing
        ),
      ]),
      catPedigreeBreedName: validateIf((data) => catIsEligible(data), [
        applyRuleIf(
          (data: Pet) => data.catBreedType === catBreedType_PEDIGREE,
          required(
            errorMessages.csPetAboutYourPetCatQuestions.pedigree_cat_breed_name
              .error_messages.missing
          )
        ),
      ]),
      catNonPedigreeBreedName: validateIf((data) => catIsEligible(data), [
        applyRuleIf(
          (data: Pet) => data.catBreedType === catBreedType_NON_PEDIGREE,
          required(
            errorMessages.csPetAboutYourPetCatQuestions.non_pedigree_cat_breed_name
              .error_messages.missing
          )
        ),
      ]),
      petGender: validateIf((data) => catIsEligible(data) || dogIsEligible(data), [
        required(
          errorMessages.csPetAboutYourPetMainQuestions.pet_gender.error_messages.missing
        ),
      ]),
      petCost: validateIf((data) => catIsEligible(data) || dogIsEligible(data), [
        required(
          errorMessages.csPetAboutYourPetMainQuestions.pet_cost.error_messages.missing
        ),
      ]),
      petSpayed: validateIf((data) => catIsEligible(data) || dogIsEligible(data), [
        required(
          errorMessages.csPetAboutYourPetMainQuestions.pet_spayed.error_messages.missing
        ),
      ]),
      petChipped: validateIf((data) => catIsEligible(data), [
        required(
          errorMessages.csPetAboutYourPetMainQuestions.pet_chipped.error_messages.missing
        ),
      ]),
      petLivesWithYou: validateIf((data) => petIsCat(data), [
        required(
          errorMessages.csPetAboutYourPetMainQuestions.pet_lives_with_you.error_messages
            .missing
        ),
        {
          validityCondition: (value) => value !== false,
          errorMessage: kickoutMessage,
          onlyValidateAfterSubmission: false,
        },
      ]),
      petInGoodHealth: validateIf(
        (data) => petIsCat(data) || (petIsDog(data) && petIsEligible(data)),
        [
          required(
            errorMessages.csPetAboutYourPetMainQuestions.pet_in_good_health.error_messages
              .missing
          ),
          {
            validityCondition: (value) => value !== false,
            errorMessage:
              errorMessages.csPetAboutYourPetMainQuestions.pet_in_good_health
                .error_messages.answered_no,
            onlyValidateAfterSubmission: false,
          },
        ]
      ),
      petDob: validateIf((data) => catIsEligible(data) || dogIsEligible(data), [
        requiredDateValue(
          errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
            .missing
        ),
        dateValueValidDay(
          errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
            .invalid_day
        ),
        dateValueValidMonth(
          errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
            .invalid_month
        ),
        dateValueValidYear(
          errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
            .year_too_short
        ),
        dateValueValid(
          errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
            .invalid_date
        ),
        applyRuleIf(
          () => !disableDateChecks,
          dateValueLessThanOrEqualTo(
            new Date(),
            errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
              .date_in_future
          )
        ),
        applyRuleIf(
          () => !disableDateChecks,
          dateValueLessThanOrEqualTo(
            /* Since the API won't accept cover start days greater than 30 days from now (UTC) we need to continue showing
            the error until the local date catches up to UTC. The rule also needs to be consistent with the max cover start date. 
            The pet's dob must be <= the latest cover start date possible - 56 days, since it must be atleast 8wks old when cover starts. 
            As the latest cover start date possible is 30days after today, this is the same as saying dob <= today - 26days */
            addDaysToDate(new Date(), -26),
            errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
              .too_young
          )
        ),
        applyRuleIf(
          () => !disableDateChecks,
          dateValueGreaterThanOrEqualTo((data) => {
            const oldPetThresholdAge = petIsDog(data) ? 20 : 25;
            return addYearsToDate(localDateToUtcDate(new Date()), -oldPetThresholdAge);
          }, errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages.too_old)
        ),
        {
          validityCondition: (value) => isAfterMinValidDate(dateValueToUtcDate(value)),
          errorMessage:
            errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth.error_messages
              .invalid_date,
        },
      ]),
    },
    warnings: {
      petIsEligible: [
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            assumptionsIncludeId(assumptions, 'eligibility_question'),
          {
            validityCondition: (value) => value !== true,
            errorMessage:
              errorMessages.csPetAggregators.assumptions.eligibility_question.warning,
          }
        ),
      ],
      petDob: [
        applyRuleIf(
          () => !disableDateChecks,
          dateValueLessThanOrEqualTo(
            /* The API compares the cover start date (UTC) to pet DOB (UTC) so we can compare from midnight UTC, rather than
          the current timezone (i.e. BST, if applicable) */
            addDaysToDate(localDateToUtcDate(new Date()), -56),
            errorMessages.csPetAboutYourPetMainQuestions.pet_date_of_birth
              .warning_messages.young_pet
          )
        ),
      ],
      petInGoodHealth: validateIf(petExistsOnCurrentQuote, [
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            assumptionsIncludeId(assumptions, 'good_health'),
          {
            validityCondition: (value) => value !== true,
            errorMessage: errorMessages.csPetAggregators.assumptions.good_health.warning,
          }
        ),
      ]),
      dogComplaints: validateIf(petExistsOnCurrentQuote, [
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            assumptionsIncludeId(assumptions, 'no_complaints_about_behaviour'),
          {
            validityCondition: (value) => value !== false,
            errorMessage:
              errorMessages.csPetAggregators.assumptions.no_complaints_about_behaviour
                .warning,
          }
        ),
      ]),
      dogLegalAction: validateIf(petExistsOnCurrentQuote, [
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            assumptionsIncludeId(assumptions, 'not_involved_in_legal_action'),
          {
            validityCondition: (value) => value !== false,
            errorMessage:
              errorMessages.csPetAggregators.assumptions.not_involved_in_legal_action
                .warning,
          }
        ),
      ]),
    },
  };
};

/*  We need to validate the rules for each pet in PetDetails individually. Therefore, we pass in the rules for each property in Pet,
    and apply them for each pet in PetsDetails in turn.
*/
const processPetRules = <T>(
  rules?: ValidationRule<T, Pet>[]
): ValidationRule<T, { petDetails: PetsDetails }>[] | undefined =>
  rules?.map(({ validityCondition, ...rest }) => ({
    ...rest,
    validityCondition: (v, data, index) => validityCondition(v, data.petDetails[index]),
  }));

const useAboutYourPetRules = (): ValidationErrorAndWarningRules<{
  petDetails: PetsDetails;
}> => {
  const petRules = usePetRules();

  return {
    errors: {
      petDetails: {
        petName: processPetRules(petRules.errors.petName),
        petType: processPetRules(petRules.errors.petType),
        petIsEligible: processPetRules(petRules.errors.petIsEligible),
        dogBreedType: processPetRules(petRules.errors.dogBreedType),
        dogPedigreeBreedName: processPetRules(petRules.errors.dogPedigreeBreedName),
        dogCrossBreedName: processPetRules(petRules.errors.dogCrossBreedName),
        mongrelSize: processPetRules(petRules.errors.mongrelSize),
        dogLegalAction: processPetRules(petRules.errors.dogLegalAction),
        dogComplaints: processPetRules(petRules.errors.dogComplaints),
        catBreedType: processPetRules(petRules.errors.catBreedType),
        catPedigreeBreedName: processPetRules(petRules.errors.catPedigreeBreedName),
        catNonPedigreeBreedName: processPetRules(petRules.errors.catNonPedigreeBreedName),
        petGender: processPetRules(petRules.errors.petGender),
        petCost: processPetRules(petRules.errors.petCost),
        petSpayed: processPetRules(petRules.errors.petSpayed),
        petChipped: processPetRules(petRules.errors.petChipped),
        petLivesWithYou: processPetRules(petRules.errors.petLivesWithYou),
        petInGoodHealth: processPetRules(petRules.errors.petInGoodHealth),
        petDob: processPetRules(petRules.errors.petDob),
      },
    },
    warnings: {
      petDetails: {
        petIsEligible: processPetRules(petRules.warnings.petIsEligible),
        petDob: processPetRules(petRules.warnings.petDob),
        petInGoodHealth: processPetRules(petRules.warnings.petInGoodHealth),
        dogLegalAction: processPetRules(petRules.warnings.dogLegalAction),
        dogComplaints: processPetRules(petRules.warnings.dogComplaints),
      },
    },
  };
};

export default useAboutYourPetRules;
