import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';

export const UPDATE_ADDITIONAL_QUESTIONS = 'UPDATE_ADDITIONAL_QUESTIONS';

export type AdditionalQuestionsShown = {
  ageQuestion?: number[];
  breedQuestion?: number[];
};

export type UpdateAdditionalQuestionsShownAction = {
  type: typeof UPDATE_ADDITIONAL_QUESTIONS;
  update: Partial<AdditionalQuestionsShown>;
};

export const initialQuestionsShown: AdditionalQuestionsShown = {
  ageQuestion: undefined,
  breedQuestion: undefined,
};

export const useAdditionalQuestionsShown = (): [
  AdditionalQuestionsShown,
  (update: Partial<AdditionalQuestionsShown>) => void
] => {
  const additionalQuestionsShown = useSelector(
    (state: RootState) => state.additionalQuestionsShown
  );

  const dispatch = useDispatch();

  const updateAdditionalQuestionsShown = useCallback(
    (update: Partial<AdditionalQuestionsShown>): void => {
      dispatch({ type: UPDATE_ADDITIONAL_QUESTIONS, update });
    },
    [dispatch]
  );

  return [additionalQuestionsShown, updateAdditionalQuestionsShown];
};
