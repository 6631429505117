import { isInt } from 'helpers/integerHelpers';
import { nations_UK } from 'helpers/referenceDataConstants';
import { AddressDetails } from 'state/formData/customerDetails';
import { QuoteAddress } from '../quoteRequest';

const mapAddress = (addressDetails: AddressDetails): QuoteAddress => ({
  town: addressDetails.town,
  county: addressDetails.county,
  street: addressDetails.street,
  postcode: addressDetails.postcode,
  country: nations_UK,
  ...(isInt(addressDetails.flatNameOrNumber)
    ? { flatNumber: addressDetails.flatNameOrNumber }
    : { flatName: addressDetails.flatNameOrNumber }),
  ...(isInt(addressDetails.houseNameOrNumber)
    ? { houseNumber: addressDetails.houseNameOrNumber }
    : { houseName: addressDetails.houseNameOrNumber }),
});

export default mapAddress;
