import { defaultContainerId } from '@rsa-digital/evo-shared-components/components/Form/idHelpers';
import { ProductId } from 'helpers/businessConstants';
import getPetIdForInput from 'helpers/getPetId';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { CurrentQuote } from 'helpers/useCurrentQuote';

export type AssumptionId =
  | 'good_health'
  | 'no_complaints_about_behaviour'
  | 'not_involved_in_legal_action'
  | 'eligibility_question'
  | 'one_pet_in_household'
  | 'two_pets_in_household'
  | 'three_pets_in_household'
  | 'pet_lives_with_you'
  | 'pet_for_make_money'
  | 'dog_breed_list_link';

export type Assumption = {
  id: AssumptionId;
  isValid: boolean;
  displayText: string;
};

export type Assumptions = {
  assumptions: Assumption[];
  fieldIdOfFirstAssumption: string;
};

type AssumptionText = {
  goodHealthSinglePet: string;
  goodHealthMultiplePets: string;
  noComplaintsAboutBehaviour: string;
  notInvolvedInLegalAction: string;
  onePetInHousehold: string;
  twoPetsInHousehold: string;
  threePetsInHousehold: string;
  eligibilityQuestion: string;
  petLivesWithYou: string;
  petForMakeMoney: string;
  dogBreedListLink: string;
};

const FIRST_PET_DOG_LEGAL_ACTION_FIELD_CONTAINER_ID = defaultContainerId(
  getPetIdForInput(0)('dogLegalAction')
);
const FIRST_PET_PET_IN_GOOD_HEALTH_FIELD_CONTAINER_ID = defaultContainerId(
  getPetIdForInput(0)('petInGoodHealth')
);
const NO_OF_PETS_IN_HOUSEHOLD_FIELD_CONTAINER_ID = defaultContainerId(
  'numberOfPetsInHousehold'
);

export const assumptionsIncludeId = (
  assumptions: Assumptions | undefined,
  id: AssumptionId
): boolean =>
  !!assumptions &&
  !!assumptions?.assumptions.find((assumption) => assumption.id === id)?.isValid;

export const getAssumptionsForQuote = (
  quote: CurrentQuote,
  assumptionText: AssumptionText,
  touchedAssumptions: AssumptionId[]
): Assumptions | undefined => {
  const GOOD_HEALTH: Assumption = {
    id: 'good_health',
    isValid:
      (quote.petInfos?.every((pet) => !pet.isPetShowingIllness) &&
        !touchedAssumptions.includes('good_health')) ||
      false,
    displayText:
      quote.petInfos?.length === 1
        ? assumptionText.goodHealthSinglePet
        : assumptionText.goodHealthMultiplePets,
  };

  const NO_COMPLAINTS_ABOUT_BEHAVIOUR: Assumption = {
    id: 'no_complaints_about_behaviour',
    isValid:
      (quote.petInfos?.every(
        // `isComplaintMade` is only defined for dogs
        (pet) => pet.petType === petType_CAT || !pet.isComplaintMade
      ) &&
        !touchedAssumptions.includes('no_complaints_about_behaviour')) ||
      false,
    displayText: assumptionText.noComplaintsAboutBehaviour,
  };

  const NOT_INVOLVED_IN_LEGAL_ACTION: Assumption = {
    id: 'not_involved_in_legal_action',
    isValid:
      (quote.petInfos?.every(
        // `legalActionInPreviousFiveYears` is only defined for dogs
        (pet) => pet.petType === petType_CAT || !pet.legalActionInPreviousFiveYears
      ) &&
        !touchedAssumptions.includes('not_involved_in_legal_action')) ||
      false,
    displayText: assumptionText.notInvolvedInLegalAction,
  };

  const ELIGIBILITY_QUESTION: Assumption = {
    id: 'eligibility_question',
    isValid:
      (quote.petInfos?.every(
        (pet) => pet.petType === petType_DOG && !pet.isPetShowingIllness
      ) &&
        !touchedAssumptions.includes('eligibility_question')) ||
      false,
    displayText: assumptionText.eligibilityQuestion,
  };

  const ONE_PET_IN_HOUSEHOLD: Assumption = {
    id: 'one_pet_in_household',
    isValid:
      quote.petInfos?.length === 1 &&
      !touchedAssumptions.includes('one_pet_in_household'),
    displayText: assumptionText.onePetInHousehold,
  };

  const TWO_PETS_IN_HOUSEHOLD: Assumption = {
    id: 'two_pets_in_household',
    isValid:
      quote.petInfos?.length === 2 &&
      !touchedAssumptions.includes('two_pets_in_household'),
    displayText: assumptionText.twoPetsInHousehold,
  };

  const THREE_PETS_IN_HOUSEHOLD: Assumption = {
    id: 'three_pets_in_household',
    isValid:
      quote.petInfos?.length === 3 &&
      !touchedAssumptions.includes('three_pets_in_household'),
    displayText: assumptionText.threePetsInHousehold,
  };

  const PET_LIVES_WITH_YOU: Assumption = {
    id: 'pet_lives_with_you',
    isValid:
      (quote.petInfos?.some((pet) => pet.petType === petType_DOG) &&
        !touchedAssumptions.includes('pet_lives_with_you')) ||
      false,
    displayText: assumptionText.petLivesWithYou,
  };

  const PET_FOR_MAKE_MONEY: Assumption = {
    id: 'pet_for_make_money',
    isValid:
      (quote.petInfos?.some((pet) => pet.petType === petType_DOG) &&
        !touchedAssumptions.includes('pet_for_make_money')) ||
      false,
    displayText: assumptionText.petForMakeMoney,
  };

  const DOG_BREED_LIST_LINK: Assumption = {
    id: 'dog_breed_list_link',
    isValid:
      (quote.petInfos?.some((pet) => pet.petType === petType_DOG) &&
        !touchedAssumptions.includes('dog_breed_list_link')) ||
      false,
    displayText: assumptionText.dogBreedListLink,
  };

  if (!quote.petInfos) {
    return undefined;
  }

  // Single pet assumptions
  if (quote.petInfos.length === 1) {
    switch (quote.productId) {
      case ProductId.COMPARE_THE_MARKET:
      case ProductId.CONFUSED: // Confused uses the same assumptions as CTM
        return quote.petInfos?.[0].petType === petType_DOG
          ? {
              assumptions: [
                ONE_PET_IN_HOUSEHOLD,
                NO_COMPLAINTS_ABOUT_BEHAVIOUR,
                GOOD_HEALTH,
                PET_LIVES_WITH_YOU,
                PET_FOR_MAKE_MONEY,
                NOT_INVOLVED_IN_LEGAL_ACTION,
                DOG_BREED_LIST_LINK,
                ELIGIBILITY_QUESTION,
              ],
              fieldIdOfFirstAssumption: FIRST_PET_DOG_LEGAL_ACTION_FIELD_CONTAINER_ID,
            }
          : {
              assumptions: [ONE_PET_IN_HOUSEHOLD, GOOD_HEALTH],
              fieldIdOfFirstAssumption: FIRST_PET_PET_IN_GOOD_HEALTH_FIELD_CONTAINER_ID,
            };
      case ProductId.GO_COMPARE:
        return {
          assumptions: [
            ONE_PET_IN_HOUSEHOLD,
            GOOD_HEALTH,
            PET_LIVES_WITH_YOU,
            PET_FOR_MAKE_MONEY,
            DOG_BREED_LIST_LINK,
          ],
          fieldIdOfFirstAssumption: NO_OF_PETS_IN_HOUSEHOLD_FIELD_CONTAINER_ID,
        };
      case ProductId.MONEY_SUPERMARKET:
        return quote.petInfos?.[0].petType === petType_DOG
          ? {
              assumptions: [
                ONE_PET_IN_HOUSEHOLD,
                GOOD_HEALTH,
                PET_LIVES_WITH_YOU,
                PET_FOR_MAKE_MONEY,
                DOG_BREED_LIST_LINK,
                ELIGIBILITY_QUESTION,
              ],
              fieldIdOfFirstAssumption: FIRST_PET_PET_IN_GOOD_HEALTH_FIELD_CONTAINER_ID,
            }
          : {
              assumptions: [ONE_PET_IN_HOUSEHOLD, GOOD_HEALTH],
              fieldIdOfFirstAssumption: FIRST_PET_PET_IN_GOOD_HEALTH_FIELD_CONTAINER_ID,
            };
      case ProductId.DIRECT:
      default:
        return undefined;
    }
  }

  // Multi-pet assumptions
  if (quote.productId === ProductId.MONEY_SUPERMARKET) {
    return {
      assumptions: [
        quote.petInfos.length === 2 ? TWO_PETS_IN_HOUSEHOLD : THREE_PETS_IN_HOUSEHOLD,
        GOOD_HEALTH,
        PET_LIVES_WITH_YOU,
        PET_FOR_MAKE_MONEY,
        DOG_BREED_LIST_LINK,
      ],
      fieldIdOfFirstAssumption: FIRST_PET_PET_IN_GOOD_HEALTH_FIELD_CONTAINER_ID,
    };
  }

  return undefined;
};
