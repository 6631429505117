import { mapApiOption } from 'apiHelpers/referenceData/mapApiOption';
import { ApiReferenceDataOption, ReferenceDataOption } from 'types/referenceData';

export type TitlesReferenceDataResponse = {
  categoryName: string;
  items: ApiReferenceDataOption[];
};

export type TitlesReferenceData = {
  titles: ReferenceDataOption[];
};

export const mapTitlesRefData = (
  data: TitlesReferenceDataResponse
): TitlesReferenceData => ({
  // Sort into reverse-alphabetical order, so 'Dr' is last in the dropdown
  titles: data.items.map(mapApiOption).sort((a, b) => {
    return a.name < b.name ? 1 : -1;
  }),
});
