export type HasKey = {
  key: number;
};

/**
 * Generates a unique key for a new item to be added to the list.
 */
export const generateKey = <T extends HasKey>(previousItems: T[]): number =>
  previousItems.length > 0 ? Math.max(...previousItems.map((v) => v.key)) + 1 : 1;

export const addItem = <T extends HasKey>(
  previousItems: T[],
  initialItem: Omit<T, 'key'>
): T[] => [
  ...previousItems,
  {
    key: generateKey(previousItems),
    ...initialItem,
  } as T,
];

export const updateItem = <T>(
  previousItems: T[],
  index: number,
  update: Partial<T>
): T[] => [
  ...previousItems.slice(0, index),
  {
    ...previousItems[index],
    ...update,
  },
  ...previousItems.slice(index + 1),
];

export const removeItem = <T>(previousItems: T[], index: number): T[] =>
  previousItems.filter((_, i) => i !== index);
