import { dateValueToISODateString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { isPet9YearsOrOlderOnDate } from 'businessLogic/petAge';
import { VoluntaryExcessAmount } from 'helpers/businessConstants';
import { conditionalValue } from 'helpers/mappingHelpers';
import {
  catBreedType_PEDIGREE,
  dogBreedType_MONGREL,
  dogBreedType_PEDIGREE,
  petType_CAT,
  petType_DOG,
} from 'helpers/referenceDataConstants';
import { PetsDetails } from 'state/formData/petsDetails';
import { QuoteRequestPetInfo } from '../quoteRequest';
import { Quote } from '../quoteResponse';

const mapPetsInfo = (
  petsDetails: PetsDetails,
  coverStartDate: Date,
  storedQuote?: Quote
): QuoteRequestPetInfo[] =>
  petsDetails.map((petDetails) => {
    const isCat = petDetails.petType === petType_CAT;
    const isPedigreeCat = isCat && petDetails.catBreedType === catBreedType_PEDIGREE;
    const isDog = petDetails.petType === petType_DOG;
    const isMongrelDog = isDog && petDetails.dogBreedType === dogBreedType_MONGREL;
    const isPedigreeDog = isDog && petDetails.dogBreedType === dogBreedType_PEDIGREE;

    const getPetBreed = (): string => {
      if (isCat) {
        return isPedigreeCat
          ? petDetails.catPedigreeBreedName
          : petDetails.catNonPedigreeBreedName;
      }
      if (isMongrelDog) {
        return '';
      }
      return isPedigreeDog
        ? petDetails.dogPedigreeBreedName
        : petDetails.dogCrossBreedName;
    };

    const getPetBreedType = (): string => {
      if (isCat) {
        return petDetails.catBreedType;
      }
      if (isMongrelDog) {
        return petDetails.mongrelSize;
      }
      return petDetails.dogBreedType;
    };

    const getPetExcess = (): VoluntaryExcessAmount => {
      // This will cause issues if 2 pets are given the same name
      // but this has been accepted as the best method with the current API
      const matchedPet = storedQuote?.petInfos.find(
        (pet) => pet.petName === petDetails.petName
      );

      if (!matchedPet) {
        return isPet9YearsOrOlderOnDate(
          dateValueToISODateString(petDetails.petDob),
          coverStartDate
        )
          ? VoluntaryExcessAmount.Excess_225
          : VoluntaryExcessAmount.Excess_125;
      }

      const previousQuotePetDob = matchedPet.dob;
      const newQuotePetDob = dateValueToISODateString(petDetails.petDob);
      // If the pet previously quoted for was 9 years older,
      // and it's age has been updated to be younger than 9,
      // we update the voluntary excess amount quoted to be £100 less
      if (
        isPet9YearsOrOlderOnDate(previousQuotePetDob, coverStartDate) &&
        !isPet9YearsOrOlderOnDate(newQuotePetDob, coverStartDate)
      ) {
        switch (matchedPet?.voluntaryExcessAmount) {
          case VoluntaryExcessAmount.Excess_150:
            return VoluntaryExcessAmount.Excess_50;
          case VoluntaryExcessAmount.Excess_200:
            return VoluntaryExcessAmount.Excess_100;
          case VoluntaryExcessAmount.Excess_225:
            return VoluntaryExcessAmount.Excess_125;
          case VoluntaryExcessAmount.Excess_250:
          default:
            return VoluntaryExcessAmount.Excess_150;
        }
      }
      // If the pet previously quoted for younger than 9 years older,
      // and it's age has been updated to be 9 or older,
      // we update the voluntary excess amount quoted to be £100 more
      if (
        !isPet9YearsOrOlderOnDate(previousQuotePetDob, coverStartDate) &&
        isPet9YearsOrOlderOnDate(newQuotePetDob, coverStartDate)
      ) {
        switch (matchedPet?.voluntaryExcessAmount) {
          case VoluntaryExcessAmount.Excess_150:
            return VoluntaryExcessAmount.Excess_250;
          case VoluntaryExcessAmount.Excess_100:
            return VoluntaryExcessAmount.Excess_200;
          case VoluntaryExcessAmount.Excess_125:
            return VoluntaryExcessAmount.Excess_225;
          case VoluntaryExcessAmount.Excess_50:
          default:
            return VoluntaryExcessAmount.Excess_150;
        }
      }

      return matchedPet.voluntaryExcessAmount;
    };

    return {
      petName: petDetails.petName,
      petType: petDetails.petType,
      petBreedType: getPetBreedType(),
      petBreed: getPetBreed(),
      petGender: petDetails.petGender,
      dob: dateValueToISODateString(petDetails.petDob) || '',
      initialCostPound: petDetails.petCost,
      initialCost: petDetails.petCost !== 0,
      isPetSpayed: petDetails.petSpayed,
      isPetChippedOrTagged: petDetails.petChipped,
      isPetAtHomeAddress: petDetails.petLivesWithYou,
      isPetShowingIllness: !petDetails.petInGoodHealth,
      isIndoorPet: conditionalValue(isCat, false),
      legalActionInPreviousFiveYears: conditionalValue(isDog, petDetails.dogLegalAction),
      isComplaintMade: conditionalValue(isDog, petDetails.dogComplaints),
      voluntaryExcessAmount: getPetExcess(),
    };
  });

export default mapPetsInfo;
