import { LookupStatus } from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { initialPromoCodeLookup, PolicyDetails } from 'state/formData/policyDetails';
import { QuotePolicyInfo } from '../quoteRequest';

const mapPolicyDetails = (policyInfo: QuotePolicyInfo): PolicyDetails => ({
  coverStartDate: policyInfo.coverStartDate,
  keepInformed: policyInfo.contactable,
  // Only valid promo codes are passed to the quote endpoint so if a code is returned
  // we can set it as successfully validated
  promoCode: policyInfo.promotionalCode
    ? {
        lookupKey: policyInfo.promotionalCode,
        data: undefined,
        status: LookupStatus.Success,
      }
    : initialPromoCodeLookup,
  referrerId: policyInfo.referrerId,
});

export default mapPolicyDetails;
