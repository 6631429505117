import React from 'react';

/**
 * AppDynamics integration works in three parts:
 * 1 - We set some config on the window that can be read by the scripts
 * 2 - Load adrum.js to bootstrap the agent
 * 3 - The agent will load adrum-ext.xxx.js asynchronously to begin instrumentation
 *
 * https://docs.appdynamics.com/display/PRO21/Inject+the+JavaScript+Agent
 */

const configScript = `
window["adrum-start-time"] = new Date().getTime();
(function(config){
  config.appKey = "${process.env.GATSBY_APPDYNAMICS_KEY}";
  config.adrumExtUrlHttp = "http://cdn.appdynamics.com";
  config.adrumExtUrlHttps = "https://cdn.appdynamics.com";
  config.beaconUrlHttp = "http://pdx-col.eum-appdynamics.com";
  config.beaconUrlHttps = "https://pdx-col.eum-appdynamics.com";
  config.useHTTPSAlways = true;
  
  config.resTiming = {"bufSize":200,"clearResTimingOnBeaconSend":true};
  config.maxUrlLength = 512;

})(window["adrum-config"] || (window["adrum-config"] = {}));
`;

const appDynamicsScripts: JSX.Element[] = [
  <script key="config">{configScript}</script>,
  <script
    key="adrum"
    data-rh="true"
    src="//cdn.appdynamics.com/adrum/adrum-23.3.0.4265.js"
    type="text/plain"
    className="optanon-category-C0002"
  />,
];

export default process.env.GATSBY_APPDYNAMICS_KEY ? appDynamicsScripts : [];
