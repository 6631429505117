import { QuoteOptions } from 'state/formData/quoteOptions';
import { getProductFromQuoteOptions, Product } from './productHelpers';

export const getCoverTypeName = (currentQuoteOptions: QuoteOptions): string => {
  const product = getProductFromQuoteOptions(currentQuoteOptions);
  switch (product) {
    case Product.Essential: {
      return 'Essential';
    }
    case Product.Standard: {
      return 'Standard';
    }
    case Product.Plus: {
      return 'Plus';
    }
    case Product.Premier: {
      return 'Premier';
    }
    default: {
      return '';
    }
  }
};
