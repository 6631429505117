import { NonTextLink } from '@rsa-digital/evo-shared-components/commonTypes/links';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { PageTitle, trackIconButtonClick } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { usePetsDetails } from 'state/formData/petsDetails';
import { CsAsset } from 'types/contentStack';
import {
  BackgroundContainer,
  Hero,
  HeroBannerDivider,
  ImageWrapper,
  SizedImage,
  StyledProgressBar,
  Subheading,
} from './styles';

type HeroBannerProps = {
  heading?: string;
  subheading?: string;
  subheadingIsPii?: boolean;
  currentStep?: QuoteAndBuyStep;
  pageTitle: PageTitle;
} & ComponentProps;

type CsHeroBanner = {
  csHeroHeader: {
    ms_bank_logo: CsAsset | null;
    logo_link_url: string;
    link_screen_reader_text: string;
  };
};

const query = graphql`
  query {
    csHeroHeader {
      ms_bank_logo {
        ...CsAsset
      }
      logo_link_url
      link_screen_reader_text
    }
  }
`;

const HeroBanner: React.FC<HeroBannerProps> = ({
  heading,
  subheading,
  subheadingIsPii,
  pageTitle,
  currentStep,
  ...props
}) => {
  const { ms_bank_logo, logo_link_url, link_screen_reader_text } = useStaticQuery<
    CsHeroBanner
  >(query).csHeroHeader;
  const msBankLogo = processImageAsset(ms_bank_logo);
  const logoLink: NonTextLink = {
    url: logo_link_url,
    screenReaderText: link_screen_reader_text,
    onClick: () => trackIconButtonClick(pageTitle, 'M&S Logo'),
  };
  const quote = useCurrentQuote();
  const petDetails = usePetsDetails()[0];
  const petNames = petDetails.map((pet) => pet.petName).join(', ');
  const getPetNameReplacer = (csString: string): string =>
    petNameReplacer(
      petNames,
      replacePlaceholdersPlainText(quotePlaceholders, quote, true)(csString)
    );
  const logoLinkSelectPagesOnly =
    pageTitle === PageTitle.AboutYouAndYourPet || pageTitle === PageTitle.Confirmation
      ? logoLink
      : undefined;

  return (
    <BackgroundContainer {...componentProps(props)}>
      {msBankLogo && (
        <ImageWrapper>
          <SizedImage link={logoLinkSelectPagesOnly} image={msBankLogo} />
        </ImageWrapper>
      )}
      {currentStep ? (
        <StyledProgressBar currentStep={currentStep} pageTitle={pageTitle} />
      ) : (
        pageTitle !== PageTitle.QuoteGenerating &&
        pageTitle !== PageTitle.AdditionalQuestions && <HeroBannerDivider />
      )}
      {heading && <Hero>{getPetNameReplacer(heading)}</Hero>}
      {subheading && (
        <Grid alignLeft>
          <GridItem desktop={9} tabletLandscape={9}>
            {subheadingIsPii ? (
              <Subheading data-cs-mask={subheadingIsPii}>
                {getPetNameReplacer(subheading)}
              </Subheading>
            ) : (
              <Subheading>{getPetNameReplacer(subheading)}</Subheading>
            )}
          </GridItem>
        </Grid>
      )}
    </BackgroundContainer>
  );
};

export default HeroBanner;
