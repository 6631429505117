import {
  PrimaryCta,
  SecondaryCta,
} from '@rsa-digital/evo-shared-components/components/Cta';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledGrid = styled(Grid)`
  & {
    margin: ${spacing(2)} 0;
  }
`;

export const StyledCtaDiv = styled.div`
  & {
    display: inline-block;
    margin: ${spacing(1)} 0;
    width: 100%;
    ${mediaQuery.tabletPortrait`
    && {
      margin: ${spacing(2)} ${spacing(2)} ${spacing(2)} 0;
      width: auto;
    }
  `}
  }
`;
export const StyledPrimaryCta = styled(PrimaryCta)`
  & {
    width: 100%;
  }
`;

export const StyledSecondaryCta = styled(SecondaryCta)`
  & {
    width: 100%;
  }
`;
