import Feature from '@rsa-digital/evo-shared-components/components/Feature';
import { GridItemWrapper } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';

export const StyledFeature = styled(Feature)`
  flex-direction: column;
  ${GridItemWrapper} {
    max-width: 640px;
    margin: 0 0 ${spacing(3)} 0;
    ${mediaQuery.tabletPortrait`
    && {
     margin: ${spacing(0)} 0;
    }
  `}
  }

  ${RichText} {
    & {
      font-weight: ${lightFontWeight};
    }
    h2 {
      margin: ${spacing(0)} 0;
      ${fonts.headingXSmall}
    }
    p {
      &.large {
        margin-top: 0;
      }
    }
    p {
      &.large + .large {
        margin-top: ${spacing(3)};
      }
    }
  }
  && {
    margin: ${spacing(0)} 0;
  }
  ${mediaQuery.tabletPortrait`
    && {
      margin: ${spacing(1)} 0;
    }
  `}
`;
