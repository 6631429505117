import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import LibraryTable, {
  TableCell,
} from '@rsa-digital/evo-shared-components/components/Table';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React, { PropsWithChildren } from 'react';
import { TableCellWithOptionalHeaderStyling } from './styles';

export type TableProps = {
  headerRow?: HeaderRow;
  bodyRows: BodyRow[];
} & ComponentProps;

export type HeaderRow = {
  cornerCellGapColSpan?: number;
  cells: {
    text: string;
    colSpan: number;
    highlight?: boolean;
    align?: 'center' | 'left';
  }[];
};

export type BodyRow = {
  cells: {
    content: string;
    colSpan: number;
    isHeader?: boolean;
    highlight?: boolean;
    align?: 'center' | 'left';
  }[];
};

/* A NOTE ON INDEXES AS KEYS:

This table component is only used when the children are RichText without custom bullets, so
there is NO STATE and hence using indexes as keys is safe. If either of the following changes are
ever made then this will need to be reconsidered:
- custom bullets are used in the rich text
- generic children are accepted in cells, and cells may be added/re-ordered/deleted */

/* eslint-disable react/no-array-index-key */

const Table: React.FC<PropsWithChildren<TableProps>> = ({
  headerRow,
  bodyRows,
  children,
  ...props
}) => (
  <LibraryTable
    {...componentProps(props)}
    hasCornerHeadingGap={!!headerRow?.cornerCellGapColSpan}>
    {headerRow && (
      <thead>
        <tr>
          {headerRow.cornerCellGapColSpan && (
            <TableCell isHeader colSpan={headerRow.cornerCellGapColSpan} />
          )}
          {headerRow.cells.map((cell, cellIndex) => (
            <TableCellWithOptionalHeaderStyling
              key={cellIndex}
              isHeader
              colSpan={cell.colSpan}
              highlight={cell.highlight}>
              {cell.text}
            </TableCellWithOptionalHeaderStyling>
          ))}
        </tr>
      </thead>
    )}
    <tbody>
      {bodyRows.map((row, rowIndex) => (
        <tr key={rowIndex}>
          {row.cells.map((cell, cellIndex) => (
            <TableCellWithOptionalHeaderStyling
              key={cellIndex}
              colSpan={cell.colSpan}
              highlight={cell.highlight}
              align={cell.align}
              isHeader={cell.isHeader}>
              <RichText html={cell.content} />
            </TableCellWithOptionalHeaderStyling>
          ))}
        </tr>
      ))}
      {children}
    </tbody>
  </LibraryTable>
);

export default Table;
