import { CoverDuration, LifetimeOption, QuoteOptions } from 'state/formData/quoteOptions';
import { CsApplicableProducts } from 'types/contentStack';

// Note this *MUST* match the strings in the 'Product' global field in CS
export enum Product {
  Essential = 'Essential',
  Standard = 'Standard',
  Plus = 'Plus',
  Premier = 'Premier',
}

export const getProductFromQuoteOptions = (
  quoteOptions: QuoteOptions
): Product | undefined => {
  if (quoteOptions.coverDuration === CoverDuration.Time_Limited) {
    return Product.Essential;
  }

  if (quoteOptions.lifetimeOption === LifetimeOption.Annual_Accident_Or_Illness_Limit) {
    return Product.Standard;
  }

  if (
    quoteOptions.lifetimeOption === LifetimeOption.Total_Annual_Limit &&
    quoteOptions.plusCoverFeeLimit
  ) {
    return Product.Plus;
  }

  if (
    quoteOptions.lifetimeOption === LifetimeOption.Total_Annual_Limit &&
    quoteOptions.premierCoverFeeLimit
  ) {
    return Product.Premier;
  }

  return undefined;
};

export const getLifetimeOptionFromProduct = (
  product: Product
): LifetimeOption | undefined => {
  switch (product) {
    case Product.Standard:
      return LifetimeOption.Annual_Accident_Or_Illness_Limit;
    case Product.Plus:
      return LifetimeOption.Total_Annual_Limit;
    case Product.Premier:
      return LifetimeOption.Total_Annual_Limit;
    default:
      return undefined;
  }
};

export const appliesToSelection = (
  quoteOptions: QuoteOptions,
  csProductSelection: CsApplicableProducts
): boolean => {
  const product = getProductFromQuoteOptions(quoteOptions);
  if (!product) {
    return false;
  }
  return csProductSelection.product_selector.includes(product);
};
