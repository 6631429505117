import { AxiosError } from 'axios';
import createAxiosError from 'test-helpers/createAxiosError';

const QUOTE_EXPIRED_ERROR_CODE = 'EVO_BUS_P174';

export const isQuoteExpiredError = (error: AxiosError): boolean =>
  error.response?.status === 404 &&
  error.response?.data?.Code === QUOTE_EXPIRED_ERROR_CODE;

export const QuoteExpiredError = createAxiosError(404, {
  Code: QUOTE_EXPIRED_ERROR_CODE,
});
