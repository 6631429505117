import { parse } from 'query-string';

export const getQueryParam = (location: Location, key: string): string | null => {
  const params = parse(location.search);
  const param = params[key];
  if (param === undefined || param === null) {
    return null;
  }
  return Array.isArray(param) ? param[0] : param;
};
