import { dateToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  initialJointPolicyholderDetails,
  JointPolicyholderDetails,
} from 'state/formData/jointPolicyholderDetails';
import { QuotePolicyInfo } from '../quoteRequest';

const mapJointPolicyholderDetails = (
  policyInfo: QuotePolicyInfo
): JointPolicyholderDetails => {
  if (!policyInfo.includeJoinPolicyHolder) {
    return initialJointPolicyholderDetails;
  }

  const jointPolicyHolder = policyInfo.joinPolicyHolder ?? undefined;
  return {
    includeJointPolicyholder: true,
    jointPolicyholderFirstName: jointPolicyHolder?.firstName ?? '',
    jointPolicyholderLastName: jointPolicyHolder?.lastName ?? '',
    jointPolicyholderDob: jointPolicyHolder?.dob
      ? dateToDateValue(new Date(jointPolicyHolder.dob))
      : {},
    jointPolicyholderTitle: jointPolicyHolder?.title ?? '',
  };
};

export default mapJointPolicyholderDetails;
