import { dateValueToISODateString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { JointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { QuoteJointPolicyHolder } from '../quoteRequest';

const mapJointPolicyHolder = (
  jointPolicyholderDetails: JointPolicyholderDetails
): QuoteJointPolicyHolder => ({
  title: jointPolicyholderDetails.jointPolicyholderTitle,
  firstName: jointPolicyholderDetails.jointPolicyholderFirstName,
  lastName: jointPolicyholderDetails.jointPolicyholderLastName,
  dob: dateValueToISODateString(jointPolicyholderDetails.jointPolicyholderDob) || '',
});

export default mapJointPolicyHolder;
