import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';

/**
 * Returns whether date checks should be suspended for time travel purposes.
 */
const useDisableDateChecks = (): boolean => {
  return useSelector((state: RootState) => state.timeTravel.disableDateChecks);
};

export default useDisableDateChecks;
