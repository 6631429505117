import { mapApiOption } from 'apiHelpers/referenceData/mapApiOption';
import { ApiReferenceDataOption, ReferenceDataOption } from 'types/referenceData';

export type DaysReferenceDataResponse = {
  categoryName: string;
  items: ApiReferenceDataOption[];
};

export type DaysReferenceData = {
  days: ReferenceDataOption[];
};

export const mapDaysRefData = (data: DaysReferenceDataResponse): DaysReferenceData => ({
  days: data.items.map(mapApiOption),
});
