import { call, CallEffect, takeEvery } from 'redux-saga/effects';
import {
  QUOTE_OPTIONS_SESSION_KEY,
  retrieveData,
  storeData,
} from 'helpers/sessionStorageHelpers';
import {
  QuoteOptions,
  UPDATE_QUOTE_OPTIONS,
  UpdateQuoteOptionsAction,
} from './quoteOptions';

function* storeQuoteOptions(
  action: UpdateQuoteOptionsAction
): Generator<CallEffect<void>> {
  yield call(() => {
    const previousQuoteOptions: QuoteOptions = JSON.parse(
      retrieveData(QUOTE_OPTIONS_SESSION_KEY) ?? '{}'
    );
    storeData(
      QUOTE_OPTIONS_SESSION_KEY,
      JSON.stringify({ ...previousQuoteOptions, ...action.update })
    );
  });
}

function* handleStoreQuoteOptions(): Generator<unknown> {
  yield takeEvery(UPDATE_QUOTE_OPTIONS, storeQuoteOptions);
}
export default handleStoreQuoteOptions;
