export const capitaliseCharacterAfterHyphenAndSpace = (input: string): string =>
  input.replace(/(\b[a-z](?!\s))/g, (x) => x.toUpperCase());

export const capitaliseFirstCharacter = (input: string): string =>
  input.length > 0 ? input[0].toUpperCase() + input.slice(1) : '';

/* This is not foolproof, and should only be used if we trust the source the string comes from (e.g. ContentStack), 
And it won't cause serious problems if it lets some HTML slip through to the output */
export const stripHtmlTags = (input: string): string => input.replace(/(<[^>]+)>/gi, '');

export const stripHtmlTagsSafe = (htmlString: string): string => {
  const virtualDocument = new DOMParser().parseFromString(htmlString, 'text/html');

  return virtualDocument.body.textContent || '';
};
