import {
  AdditionalQuestionsShown,
  initialQuestionsShown,
  UPDATE_ADDITIONAL_QUESTIONS,
  UpdateAdditionalQuestionsShownAction,
} from './additionalQuestionsShown';

const additionalQuestionsShownReducer = (
  additionalQuestionsShown: AdditionalQuestionsShown = initialQuestionsShown,
  action: UpdateAdditionalQuestionsShownAction
): AdditionalQuestionsShown => {
  switch (action.type) {
    case UPDATE_ADDITIONAL_QUESTIONS:
      return {
        ...additionalQuestionsShown,
        ...action.update,
      };
    default:
      return additionalQuestionsShown;
  }
};

export default additionalQuestionsShownReducer;
