import { RESET_STATE, ResetStateAction } from 'state/actions';
import { UpdateQuoteInProgressAction } from './quoteInProgress';
import {
  initialRetrieveQuoteDetails,
  RetrieveQuoteDetails,
  UPDATE_RETRIEVE_QUOTE_DETAILS,
  UpdateRetrieveQuoteDetailsAction,
} from './retrieveQuote';

export const retrieveQuoteDetailsReducer = (
  retrieveQuoteDetails: RetrieveQuoteDetails = initialRetrieveQuoteDetails,
  action:
    | UpdateRetrieveQuoteDetailsAction
    | UpdateQuoteInProgressAction
    | ResetStateAction
): RetrieveQuoteDetails => {
  switch (action.type) {
    case UPDATE_RETRIEVE_QUOTE_DETAILS:
      return {
        ...retrieveQuoteDetails,
        ...action.update,
      };
    case RESET_STATE:
      return initialRetrieveQuoteDetails;
    default:
      return retrieveQuoteDetails;
  }
};
