import { parseDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { Quote, QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { getFullMonths, getFullYears } from 'helpers/ageHelpers';
import { ProductId } from 'helpers/businessConstants';
import { petType_DOG } from 'helpers/referenceDataConstants';
import { CurrentQuote } from 'helpers/useCurrentQuote';

export const petAgeIsEqualToTwoMonths = (dateOfBirth: Date | undefined): boolean => {
  return dateOfBirth
    ? getFullYears(new Date(dateOfBirth)) === 0 &&
        getFullMonths(new Date(dateOfBirth)) === 2
    : false;
};

export const isDog = (petOnQuote: QuoteResponsePetInfo): boolean =>
  petOnQuote.petType === petType_DOG;

export const isCTM = (quote: Quote | CurrentQuote): boolean =>
  quote.productId === ProductId.COMPARE_THE_MARKET;

export const isConfused = (quote: Quote | CurrentQuote): boolean =>
  quote.productId === ProductId.CONFUSED;

const petRequiresAdditionalQuestionsCTM = (petOnQuote: QuoteResponsePetInfo): boolean =>
  petAgeIsEqualToTwoMonths(parseDate(petOnQuote.dob));

const petRequiresAdditionalQuestionsConfused = (
  petOnQuote: QuoteResponsePetInfo
): boolean =>
  petAgeIsEqualToTwoMonths(parseDate(petOnQuote.dob)) ||
  petOnQuote.petType === petType_DOG;

export const shouldAggsQuoteShowAdditionalQuestions = (
  quote: Quote | CurrentQuote
): boolean =>
  (isCTM(quote) &&
    (quote.petInfos?.some((petInfo) => petRequiresAdditionalQuestionsCTM(petInfo)) ??
      false)) ||
  (isConfused(quote) &&
    quote.petInfos?.length === 1 &&
    (quote.petInfos?.some((petInfo) => petRequiresAdditionalQuestionsConfused(petInfo)) ??
      false));

export const shouldAggsQuoteShowAdditionalQuestionsNonCTM = (
  quote: Quote | CurrentQuote
): boolean =>
  !isCTM(quote) &&
  isConfused(quote) &&
  quote.petInfos?.length === 1 &&
  (quote.petInfos?.some((petInfo) => petRequiresAdditionalQuestionsConfused(petInfo)) ??
    false);
