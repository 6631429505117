import { navigate } from 'gatsby';
import { PageTitle, trackTextButtonClick } from './eventTracking';

const isGatsbyNavigable = (url: string): boolean => {
  // Hash and query links aren't supported
  if (url.startsWith('#') || url.startsWith('?')) {
    return false;
  }

  // Identify internal links by determining whether the new URL is on the same origin
  // N.B. If we need to serve non-Gatsby urls on the same origin then this may not work
  // and we will need a more comprehensive solution - e.g. passing all valid URLs from a static query
  const resolved = new URL(url, window.location.href);
  return resolved.origin === window.location.origin;
};

export const handleLinkClick = (url: string) => (event: React.MouseEvent): void => {
  if (isGatsbyNavigable(url)) {
    // See https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-link/src/index.js#L214
    if (
      event.button === 0 && // ignore right clicks
      !(event.target as HTMLAnchorElement)?.target && // let browser handle "target=_blank"
      !event.defaultPrevented && // onClick prevented default
      !event.metaKey && // ignore clicks with modifier keys...
      !event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey
    ) {
      event.preventDefault();
      navigate(url);
    }
  }
};

export const handleAndTrackLinkClick = (
  url: string,
  pageTitle: PageTitle,
  buttonText: string,
  event: React.MouseEvent
): void => {
  trackTextButtonClick(pageTitle, buttonText);
  handleLinkClick(url)(event);
};
