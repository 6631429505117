import { graphql } from 'gatsby';
import React from 'react';
import { processImageAsset, processOptionalCta } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { usePetsDetails } from 'state/formData/petsDetails';
import { CsAsset, CsCta } from 'types/contentStack';
import { StyledFeature } from './styles';

type FeatureBlockProps = {
  feature: {
    title: string;
    body: string;
    image: CsAsset | null;
    cta: [CsCta] | null;
    invert: boolean;
    page_title: PageTitle;
  };
};

export const query = graphql`
  fragment FeatureBlockError on cs__error_page_blocks {
    feature {
      title
      body
      image {
        ...CsAsset
      }
      cta {
        display_text
        screen_reader_text
        url
      }
      invert
    }
  }
`;

const FeatureBlock: React.FC<FeatureBlockProps> = ({ feature }) => {
  const image = processImageAsset(feature.image);
  const quote = useCurrentQuote();
  const petDetails = usePetsDetails()[0];
  const petNames = petDetails.map((pet) => pet.petName).join(', ');
  const getPetNameReplacer = (csString: string): string =>
    petNameReplacer(
      petNames,
      replacePlaceholdersPlainText(quotePlaceholders, quote, true)(csString)
    );
  return (
    <StyledFeature
      title={
        petNames && feature?.title ? getPetNameReplacer(feature.title) : feature.title
      }
      body={petNames && feature?.body ? getPetNameReplacer(feature.body) : feature.body}
      image={image}
      cta={processOptionalCta(feature.cta, feature.page_title)}
      invertContent={feature.invert}
      data-cy="Feature"
    />
  );
};

export default React.memo(FeatureBlock);
