import { mapApiOption } from 'apiHelpers/referenceData/mapApiOption';
import { ApiReferenceDataOption, ReferenceDataOption } from 'types/referenceData';

export type CatBreedsReferenceDataResponse = {
  categoryName: string;
  items: ApiReferenceDataOption[];
};

export type CatBreedsReferenceData = {
  catBreeds: ReferenceDataOption[];
};

export const mapCatBreedsRefData = (
  data: CatBreedsReferenceDataResponse
): CatBreedsReferenceData => ({
  catBreeds: data.items.map(mapApiOption),
});
