import { Product } from 'helpers/productHelpers';

export enum CoverDuration {
  Time_Limited = 'time_limited',
  Lifetime = 'lifetime',
}

export enum LifetimeOption {
  Annual_Accident_Or_Illness_Limit = 'Annual_Accident_Or_Illness_Limit',
  Total_Annual_Limit = 'Total_Annual_Limit',
}

export enum PlusCoverFeeLimit {
  Limit_2000 = '2000',
  Limit_3000 = '3000',
}

export enum PremierCoverFeeLimit {
  Limit_5000 = '5000',
  Limit_7000 = '7000',
  Limit_10000 = '10000',
}

export const getProductsByCoverDuration = (
  coverDuration: CoverDuration | undefined
): Product[] => {
  switch (coverDuration) {
    case CoverDuration.Time_Limited:
      return [Product.Essential];
    case CoverDuration.Lifetime:
      return [Product.Standard, Product.Plus, Product.Premier];
    default:
      return [];
  }
};

export const getProductsByLifetimeOption = (
  lifetimeOption: LifetimeOption | undefined
): Product[] => {
  switch (lifetimeOption) {
    case LifetimeOption.Annual_Accident_Or_Illness_Limit:
      return [Product.Standard];
    case LifetimeOption.Total_Annual_Limit:
      return [Product.Plus, Product.Premier];
    default:
      return [];
  }
};

export type QuoteOptions = {
  isAnnualPayment: boolean;
  hasConfirmedExcessOption: boolean | undefined;
  coverDuration: CoverDuration | undefined;
  lifetimeOption: LifetimeOption | undefined;
  plusCoverFeeLimit: PlusCoverFeeLimit | undefined;
  premierCoverFeeLimit: PremierCoverFeeLimit | undefined;
  additionalQuestionEssentialCover: boolean | undefined;
  additionalQuestionStandardCover: boolean | undefined;
  additionalQuestionPlus2kCover: boolean | undefined;
  partnershipCard: boolean | undefined;
};

export const initialQuoteOptions: QuoteOptions = {
  isAnnualPayment: false,
  hasConfirmedExcessOption: undefined,
  coverDuration: undefined,
  lifetimeOption: undefined,
  plusCoverFeeLimit: undefined,
  premierCoverFeeLimit: undefined,
  additionalQuestionEssentialCover: undefined,
  additionalQuestionStandardCover: undefined,
  additionalQuestionPlus2kCover: undefined,
  partnershipCard: undefined,
};

export const UPDATE_QUOTE_OPTIONS = 'UPDATE_QUOTE_OPTIONS';

export type UpdateQuoteOptionsAction = {
  type: typeof UPDATE_QUOTE_OPTIONS;
  update: QuoteOptions;
};
