import { RESET_STATE, ResetStateAction } from 'state/actions';
import {
  ConfirmationQuote,
  UPDATE_CONFIRMATION_QUOTE,
  UpdateConfirmationQuoteAction,
} from './confirmationQuote';

export const confirmationQuoteReducer = (
  confirmationQuote: ConfirmationQuote | null = null,
  action: UpdateConfirmationQuoteAction | ResetStateAction
): ConfirmationQuote | null => {
  switch (action.type) {
    case UPDATE_CONFIRMATION_QUOTE:
      return action.update && { ...confirmationQuote, ...action.update };
    case RESET_STATE:
      return null;
    default:
      return confirmationQuote;
  }
};
