import React from 'react';
import { Helmet } from 'react-helmet-async';
import appdynamicsScripts from './appdynamics';
import { gtmNoscript, gtmScript } from './gtm';
import oneTrustScripts from './onetrust';
import optimizelyScript from './optimizely';

const Analytics: React.FC = () => (
  <>
    <Helmet>
      {oneTrustScripts}
      {optimizelyScript}
      {gtmScript}
      {appdynamicsScripts}
    </Helmet>
    {gtmNoscript}
  </>
);

export default Analytics;
