import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import { initialDateValue } from './shared/dateValue';

export const UPDATE_RETRIEVE_QUOTE_DETAILS = 'UPDATE_RETRIEVE_QUOTE_DETAILS';

export type RetrieveQuoteDetails = {
  customerFirstName: string;
  customerLastName: string;
  customerDob: DateValue;
  customerPostcode: string;
  quoteNumber: string;
};

export const initialRetrieveQuoteDetails: RetrieveQuoteDetails = {
  customerFirstName: '',
  customerLastName: '',
  customerDob: initialDateValue,
  customerPostcode: '',
  quoteNumber: '',
};

export type UpdateRetrieveQuoteDetailsAction = {
  type: typeof UPDATE_RETRIEVE_QUOTE_DETAILS;
  update: RetrieveQuoteDetails;
};

export const useRetrieveQuoteDetails = (): [
  RetrieveQuoteDetails,
  (update: Partial<RetrieveQuoteDetails>) => void
] => {
  const retrieveQuoteDetails = useSelector(
    (state: RootState) => state.retrieveQuoteDetails
  );
  const dispatch = useDispatch();

  const updateRetrieveQuoteDetails = useCallback(
    (update: Partial<RetrieveQuoteDetails>): void => {
      dispatch({ type: UPDATE_RETRIEVE_QUOTE_DETAILS, update });
    },
    [dispatch]
  );

  return [retrieveQuoteDetails, updateRetrieveQuoteDetails];
};
