import { graphql, useStaticQuery } from 'gatsby';
import { capitaliseFirstCharacter } from '../stringHelpers';

type CsPetNameDefaultText = {
  csPetAboutYouAndYourPet: {
    about_your_pet_section: {
      dynamic_pet_name_default_text: string;
    };
  };
};

export type PetDetailsOptions = {
  dynamicPetNameDefaultText: string;
};

const query = graphql`
  query {
    csPetAboutYouAndYourPet {
      about_your_pet_section {
        dynamic_pet_name_default_text
      }
    }
  }
`;

export const useDefaultPetNamePlaceholder = (): string =>
  useStaticQuery<CsPetNameDefaultText>(query).csPetAboutYouAndYourPet
    .about_your_pet_section.dynamic_pet_name_default_text;

// We capitalise the placeholder if it is at the start of the sentence so that the default
// placeholder (e.g. 'your pet') is capitalised only when it is the first word(s) in the
// sentence ('Your pet's DOB'). Otherwise it is not capitalised (e.g. 'What breed is your pet')
export const petNameReplacer = (petText: string, csString: string): string =>
  csString
    .replace(/^({{petName}})/, capitaliseFirstCharacter(petText))
    .replace(/({{petName}})/g, petText);

export const usePetNameReplacerWithDefault = (
  petName: string
): ((csString: string) => string) => {
  const defaultPetNameText = useDefaultPetNamePlaceholder();

  return (csString: string) => petNameReplacer(petName || defaultPetNameText, csString);
};
