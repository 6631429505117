import PageWidthContainer from '@rsa-digital/evo-shared-components/components/PageWidthContainer';
import ProgressBar from '@rsa-digital/evo-shared-components/components/ProgressBar';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledProgressBar = styled(ProgressBar)`
  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(-1.75)};
  `}

  ${mediaQuery.tabletPortrait`
    & > div:first-child{
    display: block;
    }
  `}
`;

export const StyledBackground = styled(PageWidthContainer)`
  display: hidden;
  ${mediaQuery.tabletPortrait`
    display: block;
    height: ${spacing(1.75)};
    background-color: ${colors.core02};
  `}
`;
