import { CustomerDetails } from './customerDetails';
import { JointPolicyholderDetails } from './jointPolicyholderDetails';
import { PetsDetails } from './petsDetails';
import { PolicyDetails } from './policyDetails';

export const UPDATE_QUOTE_IN_PROGRESS = 'UPDATE_QUOTE_IN_PROGRESS';

export type QuoteInProgress = Partial<FullQuoteFormData>;

export type FullQuoteFormData = {
  customerDetails: CustomerDetails;
  petsDetails: PetsDetails;
  jointPolicyholderDetails: JointPolicyholderDetails;
  policyDetails: PolicyDetails;
};

export type UpdateQuoteInProgressAction = {
  type: typeof UPDATE_QUOTE_IN_PROGRESS;
  quote: QuoteInProgress;
};
