import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useMetaTitle } from 'helpers/useMetaTitle';

const HTML_ATTRIBUTES = {
  lang: 'en',
};

type CsGlobalMeta = {
  csPetGlobalConfig: {
    meta: {
      favicon: {
        url: string;
      };
    };
  };
};

const metaQuery = graphql`
  query {
    csPetGlobalConfig {
      meta {
        favicon {
          url
        }
      }
    }
  }
`;

const Meta: React.FC<{ metaTitle: string }> = ({ metaTitle }) => {
  const data = useStaticQuery<CsGlobalMeta>(metaQuery).csPetGlobalConfig.meta;
  const getFullMetaTitle = useMetaTitle();

  return (
    <Helmet htmlAttributes={HTML_ATTRIBUTES}>
      <title>{getFullMetaTitle(metaTitle)}</title>
      <link rel="icon" type="image/png" href={data.favicon.url} />
      <meta name="robots" content="noindex, nofollow" />
    </Helmet>
  );
};

export default Meta;
