import { Image } from '@rsa-digital/evo-shared-components/commonTypes/image';
import SecurePaymentFooter from '@rsa-digital/evo-shared-components/components/Footer/SecurePaymentFooter';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { CsAsset } from 'types/contentStack';

type FooterData = {
  csSecurePaymentFooter: {
    footer_text: string;
    images: CsAsset[];
  };
};

const footerQuery = graphql`
  query {
    csSecurePaymentFooter {
      footer_text
      images {
        ...CsAsset
      }
    }
  }
`;

const SecurePaymentFooterBlock: React.FC = () => {
  const { csSecurePaymentFooter } = useStaticQuery<FooterData>(footerQuery);
  const { footer_text, images } = csSecurePaymentFooter;
  const processedImages = images
    .map(processImageAsset)
    .filter((image) => !!image) as Image[];

  const footerBlocks: (string | Image)[] = [footer_text, ...processedImages];
  return (
    <SecurePaymentFooter
      blocks={footerBlocks}
      data-cy="Secure payment footer"
      forwardAs="div"
    />
  );
};

export default SecurePaymentFooterBlock;
