export const isInt = (s: string | undefined): boolean => !!(s && parseInt(s, 10));

export const parseNumber = (variable: string | undefined): number | undefined => {
  if (variable) {
    const parsedVariable = parseInt(variable, 10);
    // eslint-disable-next-line no-restricted-globals
    return isNaN(parsedVariable) ? undefined : parsedVariable;
  }
  return undefined;
};
