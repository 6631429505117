import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { Color } from '@rsa-digital/evo-shared-components/theme';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { PageTitle, trackLinkClick } from 'helpers/eventTracking';
import { CsProgressBar } from 'types/contentStack';
import { StyledBackground, StyledProgressBar } from './styles';

type ProgressBarProps = {
  currentStep: QuoteAndBuyStep;
  pageTitle: PageTitle;
  progressBarColour?: Color;
} & ComponentProps;

type ProgressBarCsProps = {
  csPetProgressBar: CsProgressBar;
};

export const query = graphql`
  query {
    csPetProgressBar {
      about_you_and_your_pet_label
      check_details_label
      hide_progress_text
      payment_label
      quote_summary_label
      show_progress_text
    }
  }
`;

export enum QuoteAndBuyStep {
  AboutYouAndYourPet = 1,
  QuoteSummary = 2,
  CheckDetails = 3,
  Payment = 4,
}

const ProgressBarComponent: React.FC<ProgressBarProps> = ({
  currentStep,
  pageTitle,
  progressBarColour = colors.core03,
  ...props
}) => {
  const {
    csPetProgressBar: {
      show_progress_text,
      hide_progress_text,
      about_you_and_your_pet_label,
      quote_summary_label,
      check_details_label,
      payment_label,
    },
  } = useStaticQuery<ProgressBarCsProps>(query);

  const steps = [
    about_you_and_your_pet_label,
    quote_summary_label,
    check_details_label,
    payment_label,
  ];

  return (
    <>
      <StyledBackground />
      <StyledProgressBar
        {...componentProps(props)}
        id="progressBar"
        currentStep={currentStep}
        totalSteps={steps.length}
        stepName={steps}
        alignLeft
        desktopWidthInPercentage={50}
        tabletWidthInPercentage={75}
        progressBarColour={progressBarColour}
        showProgressText={show_progress_text}
        hideProgressText={hide_progress_text}
        onToggleProgress={(progressShown) =>
          trackLinkClick(pageTitle, progressShown ? 'Hide progress' : 'Show progress')
        }
        unifiedDisplay
      />
    </>
  );
};

export default ProgressBarComponent;
