// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@reach/router';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Layout from 'components/Layout';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import { CsBlock, mapBlocks } from 'helpers/blockMapper';
import { PageTitle, trackErrorPage } from 'helpers/eventTracking';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { useMetaTitle } from 'helpers/useMetaTitle';
import { RootState } from 'state/createStore';
import { ErrorType } from 'state/error/actions';
import { Container, Divider } from './styles';

export type ErrorPageProps = {
  data: ErrorPageData;
  sessionExpiryOption?: SessionExpiryOption;
  pageTitle?: PageTitle;
};

export type ErrorPageData = {
  csErrorPage: {
    meta_title: string;
    heading: string;
    subhead?: string;
    blocks: CsBlock[];
  };
};

export const query = graphql`
  fragment ErrorPage on cs__error_page {
    meta_title
    heading
    subhead
    blocks {
      ...CtaBlockError
      ...CtaBannerBlockError
      ...FeatureBlockError
    }
  }
`;

const convertErrorTypeToUrl = (errorType: ErrorType): string =>
  errorType.toLowerCase().replace(/_/g, '-');

const ErrorPage: React.FC<ErrorPageProps> = ({
  data,
  sessionExpiryOption = SessionExpiryOption.DEFAULT_SESSION_EXPIRY,
  pageTitle = PageTitle.TechnicalError,
}) => {
  const page = data.csErrorPage;
  const { pathname } = useLocation();
  const error = useSelector((state: RootState) => state.error);
  const getFullMetaTitle = useMetaTitle();
  const quote = useCurrentQuote();
  const quoteNumber = quote.policyInfo?.quoteNumber ?? undefined;
  const errorClassification =
    pageTitle === PageTitle.BusinessError ? 'business-error' : 'technical-error';

  useEffect(() => {
    if (error.errorType) {
      trackErrorPage(
        `${pathname}${errorClassification}/${convertErrorTypeToUrl(error.errorType)}`,
        getFullMetaTitle(data.csErrorPage.meta_title),
        quoteNumber,
        quote.productId,
        error.errorType,
        error.statusCode
      );
    }
  }, [
    data.csErrorPage.meta_title,
    error.errorType,
    error.statusCode,
    getFullMetaTitle,
    pathname,
    quote.productId,
    quoteNumber,
    errorClassification,
  ]);

  return (
    <Layout
      pageTitle={pageTitle}
      heading={page.heading}
      subheading={page.subhead}
      metaTitle={page.meta_title}
      sessionExpiryOption={sessionExpiryOption}>
      <Container>{page.blocks && mapBlocks(page.blocks, pageTitle)}</Container>
      <Divider />
    </Layout>
  );
};

export default ErrorPage;
