import { CustomerDetails } from 'state/formData/customerDetails';
import {
  gender_F,
  gender_M,
  title_MISS,
  title_MR,
  title_MRS,
  title_MS,
} from './referenceDataConstants';

const genderedTitles = [title_MR, title_MRS, title_MISS, title_MS];

/**
 * Returns whether gender can be infered from the chosen title, so that gender
 * can be automatically set rather than asking the user
 */
export const hasGenderlessTitle = (data: CustomerDetails): boolean => {
  return data.customerTitle !== '' && !genderedTitles.includes(data.customerTitle);
};

export const getInferedGenderFromTitle = (title: string): string | undefined => {
  switch (title) {
    case title_MR:
      return gender_M;
    case title_MRS:
    case title_MISS:
    case title_MS:
      return gender_F;
    default:
      return undefined;
  }
};
