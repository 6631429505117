import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${mediaQuery.tabletPortrait`
    flex-direction: column;
  `}

  ${mediaQuery.tabletLandscape`
    border-right: solid 1px ${colors.neutral04};
    margin-top: ${spacing(8)}
    justify-content: left;
    height: 80%;
    width: 100%;
  `}
`;

export const Heading = styled.h1`
  ${fonts.headingMedium}
  margin: 0 0 0 ${spacing(2)};
  text-align: center;

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(4)} 0 0;
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(7)};
  `}
`;

export const Divider = styled(FlexibleWidthDivider)`
  ${mediaQuery.tabletLandscape`
    display: none;
  `}
`;

export const TopDivider = styled(Divider)`
  margin-top: ${spacing(6)};
`;

export const BottomDivider = styled(Divider)`
  margin: ${spacing(7)} 0 ${spacing(4)};
`;

export const GridWithMargin = styled(Grid)`
  && {
    border: 0;
  }
`;

export const HeadingGridItem = styled(GridItem)`
  ${mediaQuery.tabletLandscape`
    display: flex;
    align-items: center;
  `}
`;

export const WhyChooseUsGridItem = styled(GridItem)`
  ${mediaQuery.tabletLandscape`
    padding: 0 ${spacing(3)};
  `}
`;
