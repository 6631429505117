import quoteClient from 'apiHelpers/quoteClient';
import { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CoverLevel, VoluntaryExcessAmount } from 'helpers/businessConstants';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useDisableDateChecks from 'helpers/useDisableDateChecks';
import useLoadingState from 'helpers/useLoadingState';
import { RootState } from 'state/createStore';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { UPDATE_QUOTE, UpdateQuoteAction } from 'state/quote/quote';
import {
  generateRequoteParameters,
  getCoverLevelFromQuoteOptions,
} from './bundleCoverMapping';
import { RequoteRequest } from './quoteRequest';

export type QuoteUpdaters = {
  saveQuoteOptions: (triggerEmail?: boolean) => Promise<void>;
  updatePetExcess: (
    petIndex: number,
    updatedExcess: VoluntaryExcessAmount
  ) => Promise<void>;
  isLoading: boolean;
} | null;

const useUpdateQuoteCover = (): QuoteUpdaters => {
  const storedQuote = useSelector((state: RootState) => state.quote);
  const quoteOptions = useSelector((state: RootState) => state.quoteSummaryOptions);
  const dispatch = useDispatch<Dispatch<UpdateQuoteAction>>();
  const { isLoading, withLoadingState } = useLoadingState();
  const disableDateChecks = useDisableDateChecks();
  const currentQuote = useCurrentQuote();
  const [customerDetails] = useCustomerDetails();

  if (!storedQuote) {
    return null;
  }

  const currentCoverLevelRequired = getCoverLevelFromQuoteOptions(quoteOptions);

  const contactDetailsHaveBeenUpdated = (): boolean => {
    const oldContactEmail = currentQuote.customerInfo?.email;
    const oldContactTelephone = currentQuote.customerInfo?.contactPhoneNumber;
    return (
      oldContactEmail !== customerDetails.customerEmail ||
      oldContactTelephone !== customerDetails.customerTelephone
    );
  };

  const isUpdateRequired = (triggerEmail: boolean): boolean =>
    triggerEmail ||
    storedQuote.petInfos.some(
      (pet) => pet.coverLevelRequired !== getCoverLevelFromQuoteOptions(quoteOptions)
    ) ||
    contactDetailsHaveBeenUpdated();

  const buildSaveQuoteOptionsRequest = (triggerEmail?: boolean): RequoteRequest => ({
    ...storedQuote,
    customerInfo: {
      ...storedQuote.customerInfo,
      email: customerDetails.customerEmail,
      contactPhoneNumber: customerDetails.customerTelephone,
    },
    policyInfo: { ...storedQuote.policyInfo, disableDateChecks },
    ...generateRequoteParameters(
      storedQuote,
      quoteOptions,
      currentCoverLevelRequired || CoverLevel.ESSENTIAL
    ),
    triggerCorrespondenceService: triggerEmail,
  });

  const performRequest = async (request: RequoteRequest): Promise<void> => {
    const quote = await withLoadingState(() => quoteClient.requote(request));
    dispatch({ type: UPDATE_QUOTE, quote });
  };

  const saveQuoteOptions = async (triggerEmail?: boolean): Promise<void> => {
    if (isUpdateRequired(!!triggerEmail)) {
      const request = buildSaveQuoteOptionsRequest(triggerEmail);
      await performRequest(request);
    }
  };

  const buildUpdateExcessRequest = (
    updatedPetIndex: number,
    updatedExcess: VoluntaryExcessAmount
  ): RequoteRequest => ({
    ...storedQuote,
    policyInfo: { ...storedQuote.policyInfo, disableDateChecks },
    ...generateRequoteParameters(
      storedQuote,
      quoteOptions,
      currentCoverLevelRequired || CoverLevel.ESSENTIAL
    ),
    petInfos: storedQuote.petInfos.map((pet, index) => ({
      ...pet,
      voluntaryExcessAmount:
        index === updatedPetIndex ? updatedExcess : pet.voluntaryExcessAmount,
    })),
  });

  const updatePetExcess = async (
    petIndex: number,
    updatedExcess: VoluntaryExcessAmount
  ): Promise<void> => {
    const request = buildUpdateExcessRequest(petIndex, updatedExcess);
    await performRequest(request);
  };

  return {
    saveQuoteOptions,
    updatePetExcess,
    isLoading,
  };
};

export default useUpdateQuoteCover;
